<template>
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Prospect Criteria</h5>
            <button type="button" class="btn-close" @click="$emit('closeModal')" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <p class="lead">Option Selections</p>
            <ul>
            <li v-for="(currentOption, index) in finalOptionData" :key='index'>
              <!-- v-if="currentOption" -->
              {{ checkvalue(currentOption) }}
            </li>
            </ul>
            <p class="lead">Geographic Selections</p>
            <ul>
              <li v-for="(currentGeo, index) in finalGeoData">
                {{currentGeo}}
              </li>
            </ul>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="$emit('closeModal')">Cancel</button>
          </div>
        </div>
</template>
<script>
import axios from 'axios';
export default {
  name: 'ProspectCriteria',
  props: {
    userData: [],
    passedModalData: {
      type: String,
      default() {
        return 'Dataset'
      }
    },
    prospectData: {
      type: Array,
      default:()=>[]
    }
  },
  data () {
    return {
      finalName: '',
      criteriaURL: '',
      finalData: '',
      finalOptionData: '',
      finalGeoData: '',
      prospectData:[
        {value: "BUY_MO_BUYER=Y", name: "Buy Items Via Mail"},
        {value: "BUY_MO_RESPDR=Y", name: "Respond to Offers Via Mail"},
        {value: "BUY_OL_PURCH_IND=Y", name: "Buy Items Online"},
        {value: "BUY_MEM_CLUBS=Y", name: "Buy From Membership Clubs"},
        {value: "BUY_VALUE_PRICED=Y", name: "Buy Value-Priced Items"},
        {value: "BUY_WMNS_APPAREL=Y", name: "Buy Women's Apparel"},
        {value: "BUY_WMNS_PETITE_APPAREL=Y", name: "Buy Petite-Sized Women's Apparel"},
        {value: "BUY_WMNS_PLUS_APPAREL=Y", name: "Buy Plus-Sized Women's Apparel"},
        {value: "BUY_YOUNG_WMNS_APPAREL=Y", name: "Buy Young Women's Apparel"},
        {value: "BUY_MNS_APPAREL=Y", name: "Buy Men's Apparel"},
        {value: "BUY_MNS_BIG_APPAREL=Y", name: "Buy Men's Big and Tall Apparel"},
        {value: "BUY_YOUNG_MNS_APPAREL=Y", name: "Buy Young Men's Apparel"},
        {value: "BUY_KIDS_APPAREL=Y", name: "Buy Children's Apparel"},
        {value: "BUY_HEALTH_BEAUTY=Y", name: "Buy Heath and Beauty Items"},
        {value: "BUY_COSMETICS=Y", name: "Buy Cosmetics"},
        {value: "BUY_JEWELRY=Y", name: "Buy Jewelry"},
        {value: "BUY_LUGGAGE=Y", name: "Buy Luggage"},
        {value: "CC_AMEX_PREM=Y", name: "Have an American Express Premium Card"},
        {value: "CC_AMEX_REG=Y", name: "Have an American express Card"},
        {value: "CC_DISC_PREM=Y", name: "Have a Discover Premium Card"},
        {value: "CC_DISC_REG=Y", name: "Have a Discover Card"},
        {value: "CC_GAS_PREM=Y", name: "Have a Premium Gas Card"},
        {value: "CC_GAS_REG=Y", name: "Have a Gas Card"},
        {value: "CC_MC_PREM=Y", name: "Have a Premium Mastercard"},
        {value: "CC_MC_REG=Y", name: "Have a Mastercard"},
        {value: "CC_VISA_PREM=Y", name: "Have a Premium Visa Card"},
        {value: "CC_VISA_REG=Y", name: "Have a Visa Card"},
        {value: "CC_HLDR_BANK=Y", name: "Have a Bank Credit Card"},
        {value: "CC_HLDR_GAS=Y", name: "Have a Gas Credit Card"},
        {value: "CC_HLDR_TE=Y", name: "Have a Travel or Entertainment Rewards Credit Card"},
        {value: "CC_HLDR_UNK=Y", name: "Have a Credit Card (Other or Unknown)"},
        {value: "CC_HLDR_PREM=Y", name: "Have a Premium Credit Card"},
        {value: "CC_HLDR_UPS_DEPT=Y", name: "Have an Upscale Department Store Card"},
        {value: "CC_USER=Y", name: "Use a Credit Card for Purchases"},
        {value: "CC_NEW_ISSUE=Y", name: "Have a Recent Credit Card"},
        {value: "CC_BANK_CD_IN_HH=Y", name: "Have a Credit Card in the Household"},
          {value: "INVEST_ACT=Y", name: "Be an Active Investor"},
          {value: "INVEST_PERS=Y", name: "Manage Personal Investments"},
          {value: "INVEST_RL_EST=Y", name: "Invest In Real Estate"},
          {value: "INVEST_STOCKS=Y", name: "Invest in the Stock Market"},
          {value: "INVEST_READ_FIN_NEWS=Y", name: "Read Financial News"},
          {value: "INVEST_MONEY_SEEKR=Y", name: "Seek Money for Investing"},
          {value: "INT_GRP_INVEST=Y", name: "Be Interested in Investing"},
          {value: "INVEST_FOREIGN=Y", name: "Have Foreign Investments"},
          {value: "INVEST_EST_PROP_OWN=Y", name: "Own Investment Properties"},
          {value: "INT_GRP_DONOR=Y", name: "Support Charitable Causes"},
          {value: "DONR_MAIL_ORD=Y", name: "Donate to a Charity by Mail"},
          {value: "DONR_CHARITABLE=Y", name: "Make a Charitable Contribution"},
          {value: "DONR_ANIMAL=Y", name: "Donate to Animal Welfare Causes"},
          {value: "DONR_ARTS=Y", name: "Donate to the Arts"},
          {value: "DONR_KIDS=Y", name: "Donate to Children's Causes"},
          {value: "DONR_WILDLIFE=Y", name: "Donate to Wildelife Causes"},
          {value: "DONR_ENVIRON=Y", name: "Donate to Environmental Causes"},
          {value: "DONR_HEALTH=Y", name: "Donate to Health and Wellness Causes"},
          {value: "DONR_INTL_AID=Y", name: "Donate to International Aid Organizations"},
          {value: "DONR_POL=Y", name: "Make a Political Contribution"},
          {value: "DONR_POL_CONS=Y", name: "Support Conservative Political Causes"},
          {value: "DONR_POL_LIB=Y", name: "Support Liberal Political Causes"},
          {value: "DONR_RELIG=Y", name: "Donate to Religious Causes"},
          {value: "DONR_VETS=Y", name: "Donate to Veteran's Causes"},
          {value: "DONR_OTH=Y", name: "Donate to Other Charities"},
          {value: "DONR_COMM_CHAR=Y", name: "Donate to Community Causes"},
          {value: "VET_IN_HH=Y", name: "Have a Veteran in the Household"},
          {value: "INT_OTH_PARENTING=Y", name: "Have an Interest in Parenting"},
          {value: "SINGLE_PARENT=Y", name: "Be a Single Parent"},
          {value: "BUY_INFANT_APPAREL=Y", name: "Buy Infant's Apparel"},
          {value: "BUY_KIDS_LEARN_TOYS=Y", name: "Buy Educational Toys"},
          {value: "BUY_KIDS_BABY_CARE=Y", name: "Buy Baby Care Items"},
          {value: "BUY_KIDS_SCHOOL=Y", name: "Buy School Supplies"},
          {value: "BUY_KIDS_GENL=Y", name: "Buy Children's General Merchandise"},
          {value: "YOUNG_ADULT_IN_HH=Y", name: "Have a Young Adult in the Household"},
          {value: "SR_ADULT_IN_HH=Y", name: "Have an Older Adult in the Household"},
          {value: "INT_OTH_KIDS_INTS=Y", name: "Have Children's Interests"},
          {value: "INT_OTH_GRANDKIDS=Y", name: "Have Grandchildren"},
          {value: "INT_OTH_CHRISTIAN_FAM=Y", name: "Be a Christian Family"},
          {value: "BUY_PETS=Y", name: "Buy Pet Supplies"},
          {value: "INT_OTH_PETS_HORSE=Y", name: "Have a Horse"},
          {value: "INT_OTH_PETS_CAT=Y", name: "Have a Cat"},
          {value: "INT_OTH_PETS_DOG=Y", name: "Have a Dog"},
          {value: "INT_OTH_PETS_OTH=Y", name: "Have a Pet (Other)"},
          {value: "INT_OTH_CAREER_IMP=Y", name: "View their Career as Important"},
          {value: "OCC_WORKING_WMN=Y", name: "Have a Professional Woman in the Household"},
          {value: "OCC_AFRIC_AMER_PROF=Y", name: "Have an African-American Professional in the Household"},
          {value: "OCC_SOHO_IND=Y", name: "Use a Home Office for Work"},
          {value: "INT_OTH_CAREER=Y", name: "Be Career-Focused"},
          {value: "BUY_MAGAZINES=Y", name: "Buy Magazines"},
          {value: "BUY_BOOKS=Y", name: "Buy Books"},
          {value: "BUY_AUDIO_BOOKS=Y", name: "Buy Audio Books"},
          {value: "READ_AVID=Y", name: "Be an Avid Reader"},
          {value: "READ_RELIG=Y", name: "Read Religious and Inspirational Books"},
          {value: "READ_SCIFI=Y", name: "Read Science Fiction Books"},
          {value: "READ_MAGAZINES=Y", name: "Read Magazines"},
          {value: "READ_AUDIO_BOOKS=Y", name: "Listen to Audio Books"},
          {value: "INT_GRP_READ=Y", name: "Be Interested in Reading"},
          {value: "READ_HIST_MLTRY=Y", name: "Read History and Military Books"},
          {value: "READ_CURR_AFFAIRS=Y", name: "Read Books about Current Affairs"},
          {value: "BUY_RELIGIOUS=Y", name: "Buy Religious Books and Items"},
          {value: "READ_SCIENCE_SPACE=Y", name: "Read about Science and Space"},
          {value: "MAGS=Y", name: "Buy Magazines"},
          {value: "ENT_EDUC_OL=Y", name: "Be Interested in Online Education"},
          {value: "ENT_GAMING=Y", name: "Be Interested in Video Games"},
          {value: "OCC_COMP_GENL=Y", name: "Be Interested in Computers"},
          {value: "ENT_DVD_VIDEOS=Y", name: "Buy DVD Videos"},
          {value: "ENT_TV_VIDEO_MOVIE=Y", name: "Watch TV and Movies"},
          {value: "OCC_HOME_OFF=Y", name: "Have an Office in the Home"},
          {value: "ENT_HI_END_APPL=Y", name: "Have High End Appliances"},
          {value: "ENT_STEREO=Y", name: "Have a Stereo"},
          {value: "ENT_MUSIC_PLAYR=Y", name: "Have a Music Player"},
          {value: "ENT_MUSIC_COLL=Y", name: "Collect Music"},
          {value: "ENT_MUSIC_AVID=Y", name: "Be an Avid Music Fan"},
          {value: "ENT_MOVIE_COLL=Y", name: "Collect Movies"},
          {value: "ENT_CABLE_TV=Y", name: "Have Cable Television"},
          {value: "ENT_VIDEO_GAMING=Y", name: "Play Video Games"},
          {value: "ENT_SAT_DISH=Y", name: "Have a Satellite Dish"},
          {value: "ENT_COMPUTERS=Y", name: "Use a Computer for Entertainment"},
          {value: "ENT_PC_GAMING=Y", name: "Use a Comptuer for Gaming"},
          {value: "ENT_CONS_ELEC=Y", name: "Be Interested in Consumer Electronics"},
          {value: "INT_GRP_MOVIE_MUSIC=Y", name: "Be interested in Movies and Music"},
          {value: "INT_GRP_ELEC=Y", name: "Be Interested in Technology"},
          {value: "INT_OTH_TELCOMM=Y", name: "Be Interested in Cell Phones and Telecommunication"},
          {value: "BUY_ANTIQUES=Y", name: "Buy Antiques"},
          {value: "BUY_ART=Y", name: "Collect Art"},
          {value: "ENT_THEATER=Y", name: "Attend the Theater"},
          {value: "INT_OTH_THE_ARTS=Y", name: "Have an Interest in the Arts"},
          {value: "ENT_MUS_INSTR=Y", name: "Play a Musical Instrument"},
          {value: "INT_COLL_GENL=Y", name: "Be Interested in Collectables"},
          {value: "INT_COLL_STAMPS=Y", name: "Collect Stamps"},
          {value: "INT_COLL_COINS=Y", name: "Collect Coins"},
          {value: "INT_COLL_ARTS=Y", name: "Collect Arts and Crafts"},
          {value: "INT_COLL_ANTIQUES=Y", name: "Collect Antiques"},
          {value: "INT_COLL_AVID=Y", name: "be an Avid Collector"},
          {value: "INT_GRP_COLL=Y", name: "Have a Hobby Collection"},
          {value: "INT_COLL_SPORTS=Y", name: "Collect Sports Items and Memorabilia"},
          {value: "INT_COLL_MIL=Y", name: "Collect Military Items and Memorabilia"},
          {value: "BUY_COLLECTIBLES=Y", name: "Buy Collectibles"},
          {value: "INT_HOB_DIY=Y", name: "Have a DIY Hobby"},
          {value: "INT_AUTO_WK=Y", name: "Be Interested in Auto Repair"},
          {value: "INT_HOB_SEW=Y", name: "Sew Knit or Crochet"},
          {value: "INT_HOB_WOODWK=Y", name: "Enjoy Woodworking"},
          {value: "INT_HOB_PHOTO=Y", name: "Enjoy Photography"},
          {value: "INT_AVIATION=Y", name: "Enjoy Aviation"},
          {value: "INT_HOB_CRAFTS=Y", name: "Enjoy Crafts and Handiwork"},
          {value: "INT_HOB_GARDENING=Y", name: "Enjoy Gardening"},
          {value: "BUY_GARDENING=Y", name: "Buy Gardening Supplies"},
          {value: "BUY_HOME_GARD=Y", name: "Shop for Home and Garden Items"},
          {value: "INT_GRP_HOME_IMP=Y", name: "Be Interested in Gardening and Home Improvement"},
          {value: "BUY_CRAFTS=Y", name: "Buy Craft Items"},
          {value: "BUY_PHOTO_VIDEO=Y", name: "Buy Photo and Video Items"},
          {value: "INT_OTH_SMOKING=Y", name: "Smoke Cigars Pipes or Other Tobacco"},
          {value: "INT_OTH_HOME_DEC=Y", name: "Be Interested in Home Decorating"},
          {value: "INT_HOB_HOME_IMP=Y", name: "Have a Home Improvement Hobby"},
          {value: "INT_OTH_COOK_WINE=Y", name: "Collect Wine"},
          {value: "INT_OTH_COOK_GNRL=Y", name: "Be Interested in Cooking"},
          {value: "INT_OTH_COOK_GOURMET=Y", name: "Cook Gourmet Foods"},
          {value: "INT_OTH_COOK_NAT_FOOD=Y", name: "Cook Organic and Natural Foods"},
          {value: "INT_GRP_COOK=Y", name: "Cook at Home"},
          {value: "INT_HOB_GAMES=Y", name: "Be Interested in Games"},
          {value: "INT_TRAV_CASINO=Y", name: "Gamble at a Casino"},
          {value: "INT_HOB_SWEEPS=Y", name: "Participate in Sweepstakes"},
          {value: "INT_GRP_TRAVEL=Y", name: "Travel"},
          {value: "INT_TRAV_GENL=Y", name: "Travel for Fun"},
          {value: "INT_TRAV_US=Y", name: "Travel in the United States"},
          {value: "INT_TRAV_INTL=Y", name: "Travel Internationally"},
          {value: "INT_TRAV_CRUISE=Y", name: "Take a Pleasure Cruise"},
          {value: "LIFE_HOME=Y", name: "Have a Home/Garde Lifestyle"},
          {value: "LIFE_DIY=Y", name: "Have a DIY Lifestyle"},
          {value: "LIFE_SPORTY=Y", name: "Have a Sports Lifestyle"},
          {value: "LIFE_UPSCALE=Y", name: "Have an Upscale Lifestyle"},
          {value: "LIFE_CULTURE=Y", name: "Have a Culture/Arts Lifestyle"},
          {value: "LIFE_HIGHBROW=Y", name: "Have a Highbrow Lifestyle"},
          {value: "LIFE_HT=Y", name: "Have a High Tech Lifestyle"},
          {value: "LIFE_PROF=Y", name: "Have a Professional Lifestyle"},
          {value: "INT_GRP_EXER=Y", name: "Be Interested In Exercise"},
          {value: "INT_FIT_JOG=Y", name: "Jog for Exercise"},
          {value: "INT_FIT_WALK=Y", name: "Walk For Exercise"},
          {value: "INT_FIT_AEROB=Y", name: "Do Aerobics"},
          {value: "INT_SPORT_SPECT_AUTO=Y", name: "Watch Auto Racing"},
          {value: "INT_SPORT_SPECT_TV_SPORTS=Y", name: "Watch Television Sports"},
          {value: "INT_SPORT_SPECT_FOOT=Y", name: "Watch Football"},
          {value: "INT_SPORT_SPECT_BASE=Y", name: "Watch Baseball"},
          {value: "INT_SPORT_SPECT_BSKT=Y", name: "Watch Basketball"},
          {value: "INT_SPORT_SPECT_HOCKEY=Y", name: "Watch Hockey"},
          {value: "INT_SPORT_SPECT_SOCCER=Y", name: "Watch Soccer"},
          {value: "INT_SPORT_TENNIS=Y", name: "Play Tennis"},
          {value: "INT_SPORT_GOLF=Y", name: "Play Golf"},
          {value: "INT_SPORT_SNOW_SKI=Y", name: "Ski"},
          {value: "INT_SPORT_MTRCYCL=Y", name: "Be Interested in Motorcycles"},
          {value: "INT_SPORT_NASCAR=Y", name: "Watch Nascar"},
          {value: "INT_SPORT_BOATING=Y", name: "Be Interested in Boating"},
          {value: "INT_SPORT_SCUBA=Y", name: "Be Interested in SCUBA Diving"},
          {value: "BUY_SPORT_LEIS=Y", name: "Have a Sports or Leisure Interest"},
          {value: "BUY_HUNTING=Y", name: "Hunt for Sport"},
          {value: "INT_SPORT_FISHING=Y", name: "Fish for Sport"},
          {value: "INT_SPORT_CAMP=Y", name: "Enjoy Camping and Outdoors"},
          {value: "INT_SPORT_SHOOT=Y", name: "Be Interested in Shooting and Firearms"},
          {value: "INT_GRP_SPORTS=Y", name: "Have a Sports Hobby"},
          {value: "INT_GRP_OUTDOOR=Y", name: "Have an Outdoor Interest"}
]
    }
  },
  mounted() {
    var ProspName = this.passedModalData;
    var myArray = ProspName.split(".");
    var criteriaName = myArray[0];
    this.finalName = criteriaName + '.criteria.prospect.json';
    this.criteriaURL = '/s/' + this.userData.uid + '/datasets/' + this.finalName;

    axios.get('/get_prospect_criteria', { params: {file: this.finalName, id: this.userData.uid } })
		.then(
			(response) => {
			this.finalData = response.data;
      var initialOptions = String(response.data['optionsArea']);
      var initialGeoOptions = String(response.data['geographyArea']);
      var finalOptionOutput = initialOptions.trim().split(/\s+/);
      var finalGeoOutput = initialGeoOptions.trim().split(/\s+/);
      if(typeof finalOptionOutput !== undefined) {
        this.finalOptionData = finalOptionOutput;
      } else {
        this.finalOptionData = '&nbsp;&nbsp;&nbsp;';
      }
      if(typeof finalGeoOutput !== undefined) {
        this.finalGeoData = finalGeoOutput;
      } else {
        this.finalGeoData = '&nbsp;&nbsp;&nbsp;';
      }
			}
		)
  },
  methods: {
    checkvalue(oldString){
      if(typeof oldString === 'undefined' || oldString === '&nbsp;&nbsp;&nbsp;'  || oldString === 'undefined') {
        return 'No Options Selected';
      }
      else {
        return this.prospectData.find(function(o){ return o.value===oldString }).name;
      }
    }  
  }
}
</script>