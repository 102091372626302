<template>
    <div class="container-fluid fullmetrics-contain">
          <div class="row">
            <div class="col-sm" ref="fullmetrics"></div>
          </div>
          <div class="row">
            <div class="col-sm-12" v-for="(data, index) in fullsociometric">
              <!-- start loop -->
              <div class="card mb-2 w-100">
                <div class="card-header">
                  <p class="lead">{{ index + 1 }}. {{ data.title }}</p>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-6">
                      {{ data.prose }}
                    </div>
                    <div class="col-3">
                      <BarChartTarget v-bind:targetData="data.target" />
                    </div>
                    <div class="col-3">
                      <BarChartBaseline v-bind:baseData="data.baseline" />
                    </div>
                  </div>
                </div>
              </div>
              <!-- end loop -->
            </div>
          </div>
    </div>
      
</template>
<script>
import BarChartTarget from "./FullChartTarget.vue";
import BarChartBaseline from "./FullChartBaseline.vue";
import axios from "axios";
import { onMounted, ref, defineAsyncComponent } from "vue";
export default {
  name: "app",
  components: { BarChartTarget, BarChartBaseline },
  props: {
    passedData: {
      type: String,
      default() {
        return "Dataset";
      },
    },
  },
  data() {
    return {
      fullsociometric: [],
      testDataElem: null,
      targetData: null,
      baseData: null
    };
  },
  mounted() {
    const reportName = this.passedData;
    axios
      .get("/user_dataset", {
        params: {
          dataset: "appended-" + reportName + ".csv.serializedFullSummaryResults.json",
          format: "raw",
          objid: "",
        },
      })
      .then((response) => {
        this.fullsociometric = response.data;
        const printable = reportName + ".csv.fullSociometrics.printable.pdf";
        const s =
          '<span class="float-right"><a class="mr-4" target="_blank" href="/user_report?report=' +
          printable +
          '" download="' +
          printable +
          '"><i class="fas fa-file-pdf"></i> Download as PDF...</a></span><br>&nbsp;<br>';
        this.$refs["fullmetrics"].innerHTML = s;
      });
  },
};
</script>
