<template>
    <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="uploadModalLabel">Upload a Dataset</h5>

          <button type="button" class="btn-close" @click="$emit('closeModal')"></button>
        </div>
        <div class="modal-body">
          <form id="datasetUploadFormNew" method="post" enctype="multipart/form-data">
            <div class="form-group">
              <!-- input type="file" name="file" id="fileUpload" @change="onFileChange" -->
              <input type="file" name="file" id="fileUpload" ref="file" v-on:change="handleFileUpload($event)" />
            </div>
            <div class="form-check">
              <input type="checkbox" class="form-check-input" id="makeReports" name="makeReports"
                value="makeReports" checked v-model="makeReports">
              <label for="makeReportsCheckbox" class="form-check-label
              "> Build summary and full sociometrics reports for this
                dataset.</label>
            </div>

            <div class="form-group">
              <button type="button" class="btn btn-light" @click="$emit('closeModal')">Cancel</button>
              <!-- button type="button" class="btn btn-primary" @click="$emit('openModal', {view: 'MashupDialog', dt: ''})">Upload</button -->
             <button type="button" class="btn btn-primary" data-dismiss="modal" v-on:click="processUpload()">Upload</button>
            </div>
          </form>
        </div>
      </div>
</template>
<script>
import axios from 'axios';
import { defineComponent, ref } from "vue";
export default {
  name: 'ModalDatasetUploadDialog',
  props: {
    passedModalData: {
      type: String,
      default() {
        return 'Dataset'
      }
    }
    },
    data(){
        return{
            file: null,
            makeReports: true
        }
    },
     methods: {
      processUpload(event) {
            var makeReportVal = '';
            if(this.makeReports == true) {
                makeReportVal = 'makeReports';
            } else {
                makeReportVal = 'noReport';
            }
            let formData = new FormData();
            formData.append('file', this.file);
            formData.append('makeReports', makeReportVal);
            axios({
                method : 'POST',
                url : '/dataset_upload',
                data: formData
            })
            .then(
                    (response) => {
                var d = response.data;
                var selectedIndexes = d[0];
                var dropdownValues = d[1];
                var baselineFiles = d[2];
                var retainFileName = d[3];
                this.$emit('closeModal');
                if(makeReportVal==='makeReports') {
                    this.$emit('setMashup', {si: selectedIndexes, dv: dropdownValues, bf: baselineFiles, rf: retainFileName});
                }
            })
            .catch(
                    (error) => {
                console.log(error);
                console.log('form submit failed');
            })
      },
      handleFileUpload(event){
          this.file = this.$refs.file.files[0];
      }
  }
}
</script>