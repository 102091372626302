<template>
<div>
  <div class="content-header text-center">
    <h2>{{ passedData }}</h2>
  </div>
  <div class="tabbed-padding">
    <ul class="nav nav nav-tabs">
      <li class="nav-item">
        <a class="nav-link"  :class="{active: tabOne}" @click="lifestyletab">American Lifestyles</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" :class="{active: tabTwo}" @click="summarytab">Summary Sociometrics</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" :class="{active: tabThree}" @click="sociometrictab">Full Sociometrics</a>
      </li>
      <li class="nav-item">
        <a class="nav-link" :class="{active: tabFour}" @click="heatmaptab">Geography</a>
      </li>
    </ul>
    <div class="tab-content py-5 px-2">
        <div class="container-fluid">
          <component :is="currentTab" v-bind:passedData="passedData"></component>
        </div>
    </div>
  </div>
</div>
</template>
<script>
import ReportShellLifestyle from "./ReportShellLifestyle.vue";
import ReportShellSummary from "./ReportShellSummary.vue";
import ReportShellSociometric from "./ReportShellSociometric.vue";
import ReportShellMap from "./ReportShellMap.vue";
export default {
  name: "reportshell",
  components: { ReportShellLifestyle, ReportShellSummary, ReportShellSociometric, ReportShellMap },
  props: {
    passedData: {
      type: String,
      default() {
        return "Dataset";
      },
    },
  },
  data() {
    return {
      currentTab: ReportShellLifestyle,
      tabOne: true,
      tabTwo: false,
      tabThree: false,
      tabFour: false
    };
  },
  methods: {
    lifestyletab() {
      this.currentTab = ReportShellLifestyle;
      this.tabOne = true;
      this.tabTwo = false;
      this.tabThree = false;
      this.tabFour = false;
    },
    summarytab() {
      this.currentTab = ReportShellSummary;
      this.tabTwo = true;
      this.tabOne = false;
      this.tabThree = false;
      this.tabFour = false;
    },
    sociometrictab() {
      this.currentTab = ReportShellSociometric;
      this.tabThree = true;
      this.tabOne = false;
      this.tabTwo = false;
      this.tabFour = false;
    },
    heatmaptab() {
      this.currentTab = ReportShellMap;
      this.tabFour = true;
      this.tabOne = false;
      this.tabTwo = false;
      this.tabThree = false;
    }
  }
};
</script>