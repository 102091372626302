<template>
    <div class="container-fluid min-content-height">
        <div class="content-header text-center">
            <h2>Account Information</h2>
        </div>
        <div class="card card-body">
            <p class="lead"><strong>Username:</strong> {{this.userData.user}}</p>
            <hr>
            <p class="lead"><strong>Name:</strong> {{this.userData.name}}</p>
            <hr>
            <p class="lead"><button class="float-end btn btn-lg btn-light" @click="$emit('openModal', {view: 'EditAddress', dt: userData.uid})">Edit</button><strong>Address:</strong><br>
            {{this.userData.address}}<br>
            {{this.userData.addresstwo}}<br>
            {{this.userData.city}}, {{this.userData.state}} {{this.userData.zipcode}}
            </p>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
export default {
  name: 'account',
  props: {
    userData: [],
    isAdmin: {
        type: Boolean,
        default() {
            return false
        }
    }
  },
  data () {
    return {
    }
  },
  mounted() {
      console.log(JSON.stringify(this.userData.uid));
  },
}
</script>
