<template>
<div class="mb-3 prospect-logic">
      <div class="container-fluid">
        <div class="row h-100">
          <div class="col-sm-5 my-auto">
            <select class="form-select" v-model="selectedGeo">
              <option value="zip">Zipcode</option>
              <option value="state">State</option>
              <option v-if="this.lpin" value="county">County</option>
            </select>
            </div>
            <div class="col-sm-5 my-auto">
              <input v-show="selectedGeo === 'zip'" name="zip" class="form-control" @change="submitSelectedGeo($event.target.value)"  />
              <select v-show="selectedGeo === 'state'" name="state" class="form-select" @change="submitSelectedGeo($event.target.value)">
                <option>Make a selection</option>
                <option v-if="!this.lpin" value="AK">AK</option>
                <option v-if="!this.lpin" value="AL">AL</option>
                <option v-if="!this.lpin" value="AR">AR</option>
                <option v-if="!this.lpin" value="AS">AS</option>
                <option v-if="!this.lpin" value="AZ">AZ</option>
                <option v-if="!this.lpin" value="CA">CA</option>
                <option v-if="!this.lpin" value="CO">CO</option>
                <option v-if="!this.lpin" value="CT">CT</option>
                <option v-if="!this.lpin" value="DC">DC</option>
                <option v-if="!this.lpin" value="DE">DE</option>
                <option v-if="!this.lpin" value="FL">FL</option>
                <option v-if="!this.lpin" value="GA">GA</option>
                <option v-if="!this.lpin" value="GU">GU</option>
                <option v-if="!this.lpin" value="HI">HI</option>
                <option v-if="!this.lpin" value="IA">IA</option>
                <option v-if="!this.lpin" value="ID">ID</option>
                <option v-if="!this.lpin" value="IL">IL</option>
                <option value="IN">IN</option>
                <option v-if="!this.lpin" value="KS">KS</option>
                <option v-if="!this.lpin" value="KY">KY</option>
                <option v-if="!this.lpin" value="LA">LA</option>
                <option v-if="!this.lpin" value="MA">MA</option>
                <option v-if="!this.lpin" value="MD">MD</option>
                <option v-if="!this.lpin" value="ME">ME</option>
                <option v-if="!this.lpin" value="MI">MI</option>
                <option v-if="!this.lpin" value="MN">MN</option>
                <option v-if="!this.lpin" value="MO">MO</option>
                <option v-if="!this.lpin" value="MP">MP</option>
                <option v-if="!this.lpin" value="MS">MS</option>
                <option v-if="!this.lpin" value="MT">MT</option>
                <option v-if="!this.lpin" value="NC">NC</option>
                <option v-if="!this.lpin" value="ND">ND</option>
                <option v-if="!this.lpin" value="NE">NE</option>
                <option v-if="!this.lpin" value="NH">NH</option>
                <option v-if="!this.lpin" value="NJ">NJ</option>
                <option v-if="!this.lpin" value="NM">NM</option>
                <option v-if="!this.lpin" value="NV">NV</option>
                <option v-if="!this.lpin" value="NY">NY</option>
                <option v-if="!this.lpin" value="OH">OH</option>
                <option v-if="!this.lpin" value="OK">OK</option>
                <option v-if="!this.lpin" value="OR">OR</option>
                <option v-if="!this.lpin" value="PA">PA</option>
                <option v-if="!this.lpin" value="PR">PR</option>
                <option v-if="!this.lpin" value="RI">RI</option>
                <option v-if="!this.lpin" value="SC">SC</option>
                <option v-if="!this.lpin" value="SD">SD</option>
                <option v-if="!this.lpin" value="TN">TN</option>
                <option v-if="!this.lpin" value="TX">TX</option>
                <option v-if="!this.lpin" value="UM">UM</option>
                <option v-if="!this.lpin" value="UT">UT</option>
                <option v-if="!this.lpin" value="VA">VA</option>
                <option v-if="!this.lpin" value="VI">VI</option>
                <option v-if="!this.lpin" value="VT">VT</option>
                <option v-if="!this.lpin" value="WA">WA</option>
                <option v-if="!this.lpin" value="WI">WI</option>
                <option v-if="!this.lpin" value="WV">WV</option>
                <option v-if="!this.lpin" value="WY">WY</option>
              </select>
              <select v-show="selectedGeo === 'county'" name="county" class="form-select" @change="submitSelectedGeo($event.target.value)">
                <option>Make a selection</option>
                <option value="ADAMS.County">ADAMS</option>
                <option value="ALLEN.County">ALLEN</option>
                <option value="BARTHOLOMEW.County">BARTHOLOMEW</option>
                <option value="BENTON.County">BENTON</option>
                <option value="BLACKFORD.County">BLACKFORD</option>
                <option value="BOONE.County">BOONE</option>
                <option value="BROWN.County">BROWN</option>
                <option value="CARROLL.County">CARROLL</option>
                <option value="CASS.County">CASS</option>
                <option value="CLARK.County">CLARK</option>
                <option value="CLAY.County">CLAY</option>
                <option value="CLINTON.County">CLINTON</option>
                <option value="CRAWFORD.County">CRAWFORD</option>
                <option value="DAVIESS.County">DAVIESS</option>
                <option value="DEARBORN.County">DEARBORN</option>
                <option value="DECATUR.County">DECATUR</option>
                <option value="DEKALB.County">DEKALB</option>
                <option value="DELAWARE.County">DELAWARE</option>
                <option value="DUBOIS.County">DUBOIS</option>
                <option value="ELKHART.County">ELKHART</option>
                <option value="FAYETTE.County">FAYETTE</option>
                <option value="FLOYD.County">FLOYD</option>
                <option value="FOUNTAIN.County">FOUNTAIN</option>
                <option value="FRANKLIN.County">FRANKLIN</option>
                <option value="FULTON.County">FULTON</option>
                <option value="GIBSON.County">GIBSON</option>
                <option value="GRANT.County">GRANT</option>
                <option value="GREENE.County">GREENE</option>
                <option value="HAMILTON.County">HAMILTON</option>
                <option value="HANCOCK.County">HANCOCK</option>
                <option value="HARRISON.County">HARRISON</option>
                <option value="HENDRICKS.County">HENDRICKS</option>
                <option value="HENRY.County">HENRY</option>
                <option value="HOWARD.County">HOWARD</option>
                <option value="HUNTINGTON.County">HUNTINGTON</option>
                <option value="JACKSON.County">JACKSON</option>
                <option value="JASPER.County">JASPER</option>
                <option value="JAY.County">JAY</option>
                <option value="JEFFERSON.County">JEFFERSON</option>
                <option value="JENNINGS.County">JENNINGS</option>
                <option value="JOHNSON.County">JOHNSON</option>
                <option value="KNOX.County">KNOX</option>
                <option value="KOSCIUSKO.County">KOSCIUSKO</option>
                <option value="LAGRANGE.County">LAGRANGE</option>
                <option value="LAKE.County">LAKE</option>
                <option value="LAPORTE.County">LAPORTE</option>
                <option value="LAWRENCE.County">LAWRENCE</option>
                <option value="MADISON.County">MADISON</option>
                <option value="MARION.County">MARION</option>
                <option value="MARSHALL.County">MARSHALL</option>
                <option value="MARTIN.County">MARTIN</option>
                <option value="MIAMI.County">MIAMI</option>
                <option value="MONROE.County">MONROE</option>
                <option value="MONTGOMERY.County">MONTGOMERY</option>
                <option value="MORGAN.County">MORGAN</option>
                <option value="NEWTON.County">NEWTON</option>
                <option value="NOBLE.County">NOBLE</option>
                <option value="OHIO.County">OHIO</option>
                <option value="ORANGE.County">ORANGE</option>
                <option value="OWEN.County">OWEN</option>
                <option value="PARKE.County">PARKE</option>
                <option value="PERRY.County">PERRY</option>
                <option value="PIKE.County">PIKE</option>
                <option value="PORTER.County">PORTER</option>
                <option value="POSEY.County">POSEY</option>
                <option value="PULASKI.County">PULASKI</option>
                <option value="PUTNAM.County">PUTNAM</option>
                <option value="RANDOLPH.County">RANDOLPH</option>
                <option value="RIPLEY.County">RIPLEY</option>
                <option value="RUSH.County">RUSH</option>
                <option value="SCOTT.County">SCOTT</option>
                <option value="SHELBY.County">SHELBY</option>
                <option value="SPENCER.County">SPENCER</option>
                <option value="ST.JOSEPH.County">ST.JOSEPH</option>
                <option value="STARKE.County">STARKE</option>
                <option value="STEUBEN.County">STEUBEN</option>
                <option value="SULLIVAN.County">SULLIVAN</option>
                <option value="SWITZERLAND.County">SWITZERLAND</option>
                <option value="TIPPECANOE.County">TIPPECANOE</option>
                <option value="TIPTON.County">TIPTON</option>
                <option value="UNION.County">UNION</option>
                <option value="VANDERBURGH.County">VANDERBURGH</option>
                <option value="VERMILLION.County">VERMILLION</option>
                <option value="VIGO.County">VIGO</option>
                <option value="WABASH.County">WABASH</option>
                <option value="WARREN.County">WARREN</option>
                <option value="WARRICK.County">WARRICK</option>
                <option value="WASHINGTON.County">WASHINGTON</option>
                <option value="WAYNE.County">WAYNE</option>
                <option value="WELLS.County">WELLS</option>
                <option value="WHITE.County">WHITE</option>
                <option value="WHITLEY.County">WHITLEY</option>
              </select>
              <!-- @change="submitSelectedGeo($event.target.name)" -->
            </div>
          </div>
        </div>
    </div>
</template>
<script>
export default {
  name: 'prospectList',
  props: {
    lpin: false
  },
  data() {
    return {
      selectedGeo:-1,
      selectedOption:''
    }
  },
  methods: {
    submitSelectedGeo(value) {
      // console.log('selected value is ' + value);
      this.$emit('customChangeGeo', value);
    }
  }
}
</script>