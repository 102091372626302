<template>
        <div class="container-fluid">
          <div class="row">
            <div class="col-sm" ref="summary"></div>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <div class="card mb-4" v-for="(data, index) in fullsummarymetric.target">
                <div class="card-header text-center">
                 {{ index }} (Target)
                </div>
                <div class="card-body">
                  <BarChartSummary v-bind:summaryData="data" v-bind:summaryIndex="index" />
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="card mb-4" v-for="(data, index) in fullsummarymetric.baseline">
                <div class="card-header text-center">
                  {{ index }} (Baseline)
                </div>
                <div class="card-body">
                  <BarChartSummary v-bind:summaryData="data" v-bind:summaryIndex="index" />
                </div>
              </div>
            </div>

            <!-- end loop -->
          </div>
        </div>
</template>
<script>
import BarChartSummary from "./FullBarChartSummary.vue";
import axios from "axios";
import { onMounted, ref, defineAsyncComponent } from "vue";
export default {
  name: "app",
  components: { BarChartSummary },
  props: {
    passedData: {
      type: String,
      default() {
        return "Dataset";
      },
    },
  },
  data() {
    return {
      fullsummarymetric: [],
      testDataElem: null,
      summaryData: null,
      summaryDataIndex: null
    };
  },
  mounted() {
    const reportName = this.passedData;
    axios
      .get("/user_report", {
        params: {
          report: "appended-" + reportName + ".csv.serializedSummaryResults.json",
          format: "raw",
          objid: "summaryjson",
        },
      })
      .then((response) => {
        this.fullsummarymetric = response.data;
        const printable = reportName + ".csv.summarySociometrics.printable.pdf";
        const s = '<span class="float-right"><a class="mr-4" target="_blank" href="/user_report?report=' + printable + '" download="' + printable + '"><i class="fas fa-file-pdf"></i> Download as PDF...</a></span><br>&nbsp;<br>';
        this.$refs["summary"].innerHTML = s;
      });
  },
};
</script>
