<template>
<form id="addProjectForm" method="post">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="addProjectLabel">Create a new project</h5>

            <button type="button" class="btn-close" @click="$emit('closeModal')"></button>
          </div>
          <div class="modal-body">
            <input type="hidden" name="projectid" value="" />
            <div class="form-group">
              <label>Project Name</label>
              <input type="text" name="projname" id="projectName" class="form-control">
            </div>
            <div class="form-group">
              <label>Project Color</label>
              <input type="color" name="projcolor" id="projectColor" class="form-control">
            </div>
            <div class="form-group">
              <label>Project Logo (path currently)</label>
              <input type="text" name="projlogo" id="projectLogo" class="form-control">
            </div>
            <div class="form-group">
              <label>Datasets</label>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="projDatasets" id="projDatasets1">
                <label class="form-check-label" for="flexRadioDefault1">
                  Default radio
                </label>
              </div>
              <div class="form-check">
                <input class="form-check-input" type="radio" name="projDatasets" id="projDatasets2">
                <label class="form-check-label" for="flexRadioDefault2">
                  Default radio
                </label>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-light" @click="$emit('closeModal')">Cancel</button>
            <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
              id="addProjectLabelButton">Save</button>
          </div>
        </div>
      </form>
</template>
<script>
export default {
}
</script>