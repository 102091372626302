<template>
    <div id="lifestyle" ref="lifestyle">
    </div>    
</template>
<script>
import axios from "axios";
import { onMounted, ref, defineAsyncComponent } from "vue";
import JQuery from 'jquery';
window.$ = JQuery;
export default {
  name: "app",
  props: {
    passedData: {
      type: String,
      default() {
        return "Dataset";
      },
    },
  },
  data() {
    return {
      testDataElem: null
    };
  },
  mounted() {
    const reportName = this.passedData;
    axios
      .get("/user_report", {
        params: { report: reportName + ".csv.lifestyles.dynamic.html" },
      })
      .then((response) => {
        const printable = reportName + ".csv.lifestyles.printable.pdf";
        const s =
          '<span class="float-right"><a class="mr-4" target="_blank" href="/user_report?report=' +
          printable +
          '" download="' +
          printable +
          '"><i class="fas fa-file-pdf"></i> Download as PDF...</a></span><br>&nbsp;<br>';
        this.$refs["lifestyle"].innerHTML = s + response.data;
        $('svg.radial-progress').each(function( index, value ) { 
            $(this).find($('circle.complete')).removeAttr( 'style' );
        });
        $(window).scroll(function(){
          $('svg.radial-progress').each(function( index, value ) { 
            // If svg.radial-progress is approximately 25% vertically into the window when scrolling from the top or the bottom
            var checkOne = $(this).offset().top - ($(window).height() * 0.75);
            var checkTwo = $(this).offset().top + $(this).height() - ($(window).height() * 0.25);
            if ( 
              $(window).scrollTop() > checkOne && $(window).scrollTop() < checkTwo
            ) {
              var percent = $(value).data('percentage');
              var radius = $(this).find($('circle.complete')).attr('r');
              var circumference = 2 * Math.PI * radius;
              var strokeDashOffset = circumference - ((percent * circumference) / 100);
              $(this).find($('circle.complete')).animate({'stroke-dashoffset': strokeDashOffset}, 1250);
            }
          });
        }).trigger('scroll');
      });
  }
};
</script>
