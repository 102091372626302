<template>
<div>
<div class="content-header text-center">
	<h2>Get Prospects</h2>
</div>
  <div class="container-fluid min-content-height">
  <form id="prospects" method="post" @submit.prevent="processProspectEvent">
    <div class="row g-3 align-items-center">
    <div class="col-sm-3">
      <label for="prospectName" class="form-label">Prospect File Name</label>
      <div class="input-group">
        <input type="text"  @keydown.space="(event) => event.preventDefault()" class="form-control" name="prospectName" id="prospectName" />
        <span class="input-group-text" id="basic-addon1">
            .csv
        </span>
        </div>
    </div>
  
    <div class="col-sm-3">
      <label for="prospectsDatasetSelect" class="form-label">Primary Dataset</label>
      <select name="prospectsDatasetSelect" class="form-select">
        <option v-for="option in datasets" :value="option" :key="option">{{option}}</option>
      </select>
    </div>
  
    <div class="col-sm-3">
      <label for="prospectsQuantity" class="form-label">Quantity</label>
      <select name="prospectsQuantity" id="prospectsQuantity" class="form-select">
        <option value="100">100</option>
        <option value="500">500</option>
        <option value="1000">1000</option>
        <option value="5000">5000</option>
        <option value="10000">10000</option>
      </select>
    </div>

    <div class="col-sm-3">
      <label>Model Strictness</label>
      <Slider v-model="percent" class="slider-blue" :min="10" />
    </div>

    </div>

    <div class="container-fluid">
      <div class="row h-100 p-4">
        <div class="col-sm-12 text-center h-100">
          <button type="button" class="my-auto change-btn btn btn-lg btn-info" value="geo-logic" @click="addNewGeo"><i class="fa-solid fa-circle-plus"></i> Add Geographic Data</button>
        </div>
      </div>
    </div>

    <ProspectsGeography v-for="(item, index) in geoitems" :key="index" :geoCompName="item.geoCompName" @customChangeGeo="customChangeGeo" v-bind:lpin="lpin" /> 
    
    <div class="mb-3 d-none">
      <label for="geographyArea" class="form-label">Geography</label>
      <textarea name="geographyArea" id="geographyArea" class="form-control" rows="5" cols="50" @model="finalGeo">{{finalGeo}}</textarea>
    </div>

    <hr>

    <div class="container-fluid">
      <div class="row h-100 p-4">
        <div class="col-sm-12 text-center h-100">
          <button type="button" class="my-auto change-btn btn btn-lg btn-info" value="prospect-logic" @click="addNewProspect"><i class="fa-solid fa-circle-plus"></i> Add Prospect Data</button>
        </div>
      </div>
    </div>

        <ProspectList v-for="(item, index) in items" :key="index" :componentName="item.componentName" @customChangeProsp="customChangeProsp" />

    <div class="mb-3 d-none">
      <label for="optionsArea" class="form-label">Options</label>
      <textarea name="optionsArea" id="optionsArea" class="form-control" rows="5" cols="50" @model="finalLogic">{{finalLogic}}</textarea>
    </div>
  
    <button type="submit" class="btn btn-lg btn-block btn-primary">Generate Prospects</button>
    
  </form>
  </div>
</div>
</template>
<script>
import axios from 'axios';
import { ref } from 'vue'; 
import ProspectList from "./ProspectsList.vue";
import ProspectsGeography from "./ProspectsGeography.vue";
import Slider from '@vueform/slider';
export default {
  name: 'prospects',
  components: { ProspectList, ProspectsGeography, Slider },
  setup() {
    let items = ref([]);
    let geoitems = ref([]);
    const addNewProspect = () => {
      items.value.push({
        componentName: "ProspectList",
      });
    };
    const addNewGeo = () => {
      geoitems.value.push({
        geoCompName: "ProspectsGeography",
      });
    };
    return {
      items,
      geoitems,
      addNewProspect,
      addNewGeo
    }
  },
  data() {
    return {
      counter: 0,
      datasets: [],
      prospectData: [],
      geographyData: [],
      finalGeo: '',
      finalLogic: '',
      percent: 10,
      lpin: false
    }
  },
  mounted () {
    axios({
      method : 'POST',
      url : '/check_lpin',
      data:'',
    }).then(
      (response) => {
      this.lpin = response.data;
      })
      .catch(error => {
        this.errorMessage = error.message;
        console.error("There was an error!", error);
    });
    axios({
      method : 'POST',
      url : '/list_datasets',
      data:'',
    }).then(response => this.datasets = processDatasetList(response.data))
      .catch(error => {
        this.errorMessage = error.message;
        console.error("There was an error!", error);
    });
  },
  methods: {
    customChangeProsp(file) {
      this.prospectData.push( file );
      this.finalLogic = this.prospectData.join('=Y\n').concat('=Y');
    },
    customChangeGeo(file) {
      this.geographyData.push( file );
      this.finalGeo = this.geographyData.join('\n').concat('');
    },
    processProspectEvent() {
      const intialFormData = new FormData(document.getElementById("prospects"));
      intialFormData.append('percent', this.percent);
      const serializedProspects = new URLSearchParams(intialFormData).toString();
      console.log(serializedProspects);
      axios.post('/prospect_download', {
        body: serializedProspects
      })
      .then(
			(response) => {
        this.$emit('messageAlert', {alert: 'Your prospect data is being processed and will be available in a few minutes.', classel: 'info'});
        this.$emit('customChange', {shell: 'ProspectsDashboard', dataset: ''});
      })
      .catch(
			(error) => {
        console.log(error);
        console.log('form submit failed');
      });
    }
  }
}
function processDatasetList(data) {
      var iH = [];
      var i;
      var b = data;
      for (i = 0; i < b.length; i++) {
        if (b[i].includes('scored-') || b[i].includes('prospect'))
          continue;
            iH.push(b[i]);
      };
      return iH;
}
</script>
<style src="@vueform/slider/themes/default.css"></style>