<template>
<form id="renameDatasetForm" method="post" @submit.prevent="customRenameProspect">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Rename Prospect List: <span id="renameDatasetOriginalNameSpan">{{passedModalData}}</span></h5>
            <button type="button" class="btn-close" @click="$emit('closeModal')" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="form-group">
              <label class="small mb-1" for="inputDataset">New Prospect List Name</label>
              <input type="hidden" name="old_dataset" id="old_dataset" :value="passedModalData">
              <input @keydown.space="(event) => event.preventDefault()" class="form-control py-4" name="new_dataset" id="renameDatasetInput" value=""
                placeholder="New Dataset Name" />
              <div class="form-text">Spaces have been disabled, please use an underscore or dash between words.</div>
            </div>

          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-light" @click="$emit('closeModal')">Cancel</button>
            <button type="submit" class="btn btn-primary">Rename</button>
          </div>
        </div>
      </form>
</template>
<script>
import axios from 'axios';
var DomParser = require('dom-parser');
var parser = new DomParser();
var host = window.location.origin;
export default {
  name: 'ModalRenameProspectForm',
  data () {
    return {
    }
  },
  props: {
    passedModalData: {
      type: String,
      default() {
        return 'Dataset'
      }
    }
  },
  methods : {
    customRenameProspect() {
      const olddata = document.getElementById("old_dataset").value;
      const tempdata = document.getElementById("renameDatasetInput").value;
      const newdata = tempdata + '.prospect';
      const intialFormData = new FormData(document.getElementById("renameDatasetForm"));
      const serializedData = new URLSearchParams(intialFormData).toString()
      axios.post('/rename_dataset', {headers: {'Content-type': 'application/x-www-form-urlencoded'}, body: serializedData}, { params: {old_dataset: olddata , new_dataset: newdata}})
      .then(
			(response) => {
        this.$emit('messageAlert', {alert: 'Renaming Prospect List', classel: 'info'});
        this.$emit('closeModal');
        this.$emit('customChange', {shell: 'ProspectsDashboard', dataset: ''});
      })
      .catch(
			(error) => {
        console.log(error);
        console.log('form submit failed');
      });
    }
  }
}
</script>