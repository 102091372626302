<template>
  <Doughnut
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"
  />
</template>

<script>
// import { defineComponent, h, PropType } from 'vue'

import { Doughnut } from 'vue-chartjs'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
  Plugin
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale)

export default {
  name: 'BarChartBaseline',
  components: {
    Doughnut
  },
  props: {
    baseData: {
      type: String
    },
    chartId: {
      type: String,
      default: 'doughnut-chart'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 400
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      chartData: {
        labels: ["Yes", "No"],
        datasets: [
            {
            backgroundColor: ["#000080", "#808080"],
            data: [Math.round(this.baseData * 100) / 100, (100 - (Math.round(this.baseData * 100) / 100))]
            }
        ]
      },
      chartOptions: {
        responsive: true,
        rotation: 60,
        plugins: {
            legend: {
                position: 'top',
            },
            title: {
                display: true,
                text: 'Baseline'
            },
            labels: {
                render: "percentages",
                fontColor: "#FFFFFF"
            }
        }
      }
    }
  }
}
</script>