<template>
 <Bar
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"
  />
</template>

<script>
// import { defineComponent, h, PropType } from 'vue'

import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  name: 'BarChartSummary',
  components: {
    Bar
  },
  props: {
    summaryIndex: {
      type: String
    },
    summaryData: {
      type: Array
    },
    chartId: {
      type: String,
      default: 'bar-chart'
    },
    width: {
      type: Number,
      default: 400
    },
    height: {
      type: Number,
      default: 400
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      chartData: {
        labels: createLabels(this.summaryData),
        datasets: [{
            label: this.summaryIndex,
            data: createDataArray(this.summaryData),
            backgroundColor: "#000080"
        }]
      },
      chartOptions: {
        responsive: true,
        plugins: {
            title: {
                display: true,
                text: this.summaryIndex
            },
            legend: {
                display: false
            },
            labels: {
                render: function (args) {
                    return Math.round(args.value) + "%";
                }
            }
        },
        scales: {
            y: {
                ticks: {
                    // Include a percent sign in the ticks
                    callback: function (value, index, values) {
                    return value + "%";
                    }
                }
            }
        }
      }
      
    }
  }
}
function createLabels(d) {
        var iH = [];
        var i;
        var b = d.flat();
        for (i = 0; i < b.length; i++) {
            if (typeof b[i] === 'string') {
                iH.push(b[i]);
            } 
        };
        return iH;
    }

    function createDataArray(d) {
        var iH = [];
        var i;
        var b = d.flat();
        for (i = 0; i < b.length; i++) {
            if (typeof b[i] === 'number') {
              iH.push(b[i]);
            } 
        };
        return iH.flat();  
    }
</script>