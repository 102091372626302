import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import {createApp} from "vue";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import Vuelidate from '@vuelidate/core';
import App from "./App.vue";
const appl = createApp(App);
appl.mount("#app");
appl.use(Toast, { timeout: false });
appl.use(Vuelidate);