<template>
    <div>

                        <div class="py-4">
                            <div class="dropdown">
                                <div class="alert alert-secondary" role="alert">
                                    <p class="lead">Welcome Back, {{this.userData.name}}</p>
                                    <p v-if="this.isAdmin"><em>Administrator</em></p>
                                </div>
                            </div>
                        </div>
                        <div class="content-header text-center">
                            <h2>Project Dashboard</h2>
                          </div>
                    <div class="container-fluid min-content-height">
                        <div class="row">
                            <div class="col-xl-4 col-lg-6">
                                <div class="card bg-light mb-4 h-100">
                                    <div class="card-header"><h4><i class="fas fa-database"></i> My Datasets</h4></div>
                                        <div class="card-body">
                                            <div class="d-grid gap-2">
                                                <a class="btn btn-lg btn-block btn-outline-primary" @click="$emit('openModal', {view: 'DatsetUploadDialog', dt: ''})">Upload a Dataset <i class="fas fa-angle-right"></i></a>
                                                <a class="btn btn-lg btn-block btn-outline-primary" @click="$emit('customChange', {shell: 'Datasets', dataset: ''})">Manage My Datasets <i class="fas fa-angle-right"></i></a>
                                                <a class="d-none delete-self btn btn-lg btn-light">Delete this</a>
                                            </div>
                                        </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-6">
                                <div class="card bg-light mb-4 h-100">
                                    <div class="card-header"><h4><i class="fas fa-chart-line"></i> My Reports</h4></div>
                                    <div class="card-body">
                                        <div class="d-grid gap-2">
                                            <a class="btn btn-lg btn-block btn-outline-primary" @click="$emit('customChange', {shell: 'Reports', dataset: ''})">Manage Reports <i class="fas fa-angle-right"></i></a>
                                            <!-- <a class="btn btn-lg btn-block btn-outline-primary" href="#"> See My Reports</a> <i class="fas fa-angle-right"></i> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 offset-lg-0 col-lg-6">
                                <div class="card bg-light mb-4 h-100">
                                    <div class="card-header"><h4><i class="fas fa-users"></i> My Prospects</h4></div>
                                    <div class="card-body">
                                        <div class="d-grid gap-2">
                                            <a class="btn btn-lg btn-block btn-outline-primary" href="#" @click="$emit('customChange', {shell: 'Prospects', dataset: ''})">Get More Prospects <i class="fas fa-angle-right"></i></a>
                                            <!-- <a class="btn btn-lg btn-block btn-outline-primary" href="#">See My Prospect Files <i class="fas fa-angle-right"></i></a> -->
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
    </div>
</template>
<script>
export default {
    name: 'Dashboard',
    props: {
        userData: [],
        isAdmin: {
            type: Boolean,
            default() {
                return false
            }
        }
    }
}
</script>