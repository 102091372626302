<template>
<div :class="this.userData.organization">
<nav class="navbar navbar-top navbar-expand navbar-dashboard navbar-dark dark_blue_bg ps-4 pe-4">
    <div class="container-fluid px-0">
      <div class="d-flex justify-content-between w-100" id="navbarSupportedContent">
        <a class="d-lg-none btn btn-lg btn-link top-level-nav-index" type="button" data-bs-toggle="collapse"
          data-bs-target="#sidebarMenu" aria-controls="sidebarMenu" aria-expanded="false"
          aria-label="Toggle navigation">
          <div class="fas fa-bars fa-2x text-white"></div>
        </a>
        <div class="d-flex align-items-center navbar-element-m">
          <a class="navbar-brand" v-on:click="returnHome">
          <img :src="imagelogo" class="img-fluid top-logo" /></a>
        </div>
        <ul class="navbar-nav align-items-center top-level-nav-index">
          <!-- start notification  -->
          <li class="nav-item dropdown d-none">
            <a class="nav-link text-dark notification-bell dropdown-toggle" data-unread-notifications="false" href="#" role="button" data-bs-toggle="dropdown" data-bs-display="static" aria-expanded="false">
              <div class="fas fa-bell fa-2x text-white"></div>
            </a>
            <div class="dropdown-menu dropdown-menu-lg dropdown-menu-center mt-2 py-0">
              <div id="usernotif" class="list-group list-group-flush">
                <a href="#" class="text-center text-primary fw-bold border-bottom border-light py-3">Notifications</a>
              </div>
            </div>
          </li>
          <!-- end notification -->
          <li class="nav-item dropdown d-none">
            <a class="nav-link text-dark notification-bell" @click="openModal({view: 'ConfigEditDialog', dt: ''})">
              <div class="fas fa-tools fa-2x text-white"></div>
            </a>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link text-dark notification-bell dropdown-toggle" href="#" role="button"
              data-bs-toggle="dropdown" aria-expanded="false">
              <div class="fas fa-user fa-2x text-white"></div>
            </a>
            <div class="dropdown-menu dashboard-dropdown dropdown-menu-end mt-2 py-1">
              <a class="dropdown-item d-flex align-items-center" v-on:click="account">Account Info</a>
              <a class="dropdown-item d-flex align-items-center" @click="openModal({view: 'UpdatePasswordDialog', dt: ''})">Change Password</a>
              <a class="dropdown-item d-flex align-items-center" href="#!">Activity Log</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item d-flex align-items-center" href="/"><svg class="dropdown-icon text-danger me-2"
                  fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1">
                  </path>
                </svg>
                Logout</a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  <!-- start main container area -->
  <div class="container-fluid contain-content h-100">
    <div class="row g-0 h-100">
      <div class="col-lg-3">
        <!-- start sidebar -->
        <!-- {% include 'includes/sidebar.html' %} -->

        <nav id="sidebarMenu" class="sidebar d-lg-block sidebar-grey-bg collapse" data-simplebar>
          <div class="sidebar-inner px-2 pt-2" id="sidebarinnerNav">
            <div class="user-card d-flex d-lg-none flex-row-reverse pb-4">

              <div class="collapse-close d-lg-none">
                <a href="#sidebarMenu" data-bs-toggle="collapse" data-bs-target="#sidebarMenu"
                  aria-controls="sidebarMenu" aria-expanded="true" aria-label="Toggle navigation">
                  <svg class="icon icon-xs" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clip-rule="evenodd"></path>
                  </svg>
                </a>
              </div>
            </div>
            <ul id="sidenav-menu" class="nav flex-column pt-3 pt-md-0">
              <li class="sb-sidenav-menu-heading">Home</li>
              <li class="nav-item">
                <a class="nav-link side-nav-link" v-on:click="dashboard">
                  <span class="sidebar-icon">
                    <div class="fas fa-home"></div>
                  </span>
                  <span class="sidebar-text">Dashboard</span>
                </a>
              </li>
              <li class="nav-item" v-if="this.isAdmin">
                <a class="nav-link side-nav-link" v-on:click="projects">
                <!-- onclick="UpdateMainContent(&quot;projects&quot;);" -->
                  <span class="sidebar-icon">
                    <div class="fas fa-diagram-project"></div>
                  </span>
                  <span class="sidebar-text">Projects</span>
                </a>
              </li>
              <li class="sb-sidenav-menu-heading">My Projects</li>
              <li class="nav-item">
                <a class="nav-link side-nav-link"  v-on:click="datasets"> 
                  <!-- onclick="UpdateMainContent(&quot;datasets&quot;);" -->
                  <span class="sidebar-icon">
                    <div class="fas fa-database"></div>
                  </span>
                  <span class="sidebar-text">Datasets</span>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link side-nav-link" v-on:click="reports">
                  <span class="sidebar-icon">
                    <div class="fas fa-chart-line"></div>
                  </span>
                  <span class="sidebar-text">Reports</span>
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link side-nav-link" v-on:click="prospects">
                  <span class="sidebar-icon">
                    <div class="fas fa-users"></div>
                  </span>
                  <span class="sidebar-text">Prospects</span>
                </a>
              </li>
            </ul>
          </div>
        </nav>
        <!-- end sidebar -->
      </div>
      <div class="col-lg-9">
        <main class="content">
          <!-- dynamic area -->
          <div class="alert-primary text-center" id="alertmsgbox"></div>
          <div id="innerapp">
              <!-- button class="btn btn-lg btn-light" @click="openModal({view: 'RenameDatasetForm', dt: 'DATASET_TEST_v1'})">Test Modal</button -->
              <component v-bind:is="component" @custom-change="customChange" @message-alert="messageAlert" @open-modal="openModal" v-bind:isAdmin="isAdmin" v-bind:passedData="passedData" v-bind:userData="userData"></component>
              <!-- modal  @modal-active="modalActive" ref="mainModal" v-bind:passedView="passedView" v-bind:passedDataset="passedDataset"></modal -->
              <!--  @open-modal="openModal" -->
              <div>
                <div ref="modal" class="modal fade" :class="{ show: active, 'd-block': active }" tabindex="-1" role="dialog">
                    <div class="modal-dialog" role="document">
                        <component v-bind:is="modalcontent" @custom-change="customChange" @message-alert="messageAlert" v-bind:passedModalData="passedModalData" v-bind:passedMashupSi="passedMashupSi" v-bind:passedMashupDv="passedMashupDv" v-bind:passedMashupBf="passedMashupBf" v-bind:passedMashupRf="passedMashupRf" v-bind:userData="userData" @close-modal="closeModal" @open-modal="openModal" @set-mashup="setMashup" />
                    </div>
                </div>
                <div v-if="active" class="modal-backdrop fade show"></div>
            </div>
          </div>
          <!-- end dynamic area -->
        </main>
        <footer class="footer-placed bg-white rounded shadow p-2 position-sticky bottom-0 end-0">
          <div class="row">
            <div class="col-sm">
              <p class="float-start text-left"><small>Copyright &copy; Analyze Corp. 2014-2022</small></p>
            </div>
            <div class="col-sm">
              <p class="float-end text-right"><small><a href="https://analyzecorp.com/terms-of-service">Privacy
                    Policy</a> . <a href="https://analyzecorp.com/privacypolicy">Terms &amp; Conditions</a></small></p>
            </div>
          </div>
        </footer>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import axios from 'axios';
import { useToast } from "vue-toastification";
import Dashboard from './views/Dashboard.vue'
import Datasets from './views/Datasets.vue'
import Projects from './views/Projects.vue'
import Reports from './views/Reports.vue'
import Prospects from './views/Prospects.vue'
import ProspectsDashboard from './views/ProspectsDashboard.vue'
import ReportShell from './views/ReportShell.vue'
import DataShell from './views/DataShell.vue' 
import RenameDatasetForm from "./views/ModalRenameDatasetForm.vue";
import RenameProspect from "./views/ProspectRenameProspect.vue";
import ProspectCriteria from "./views/ProspectCriteria.vue";
import TransferDatasetForm from "./views/ModalTransferDatasetForm.vue";
import DatsetUploadDialog from "./views/ModalDatsetUploadDialog.vue";
import UpdatePasswordDialog from "./views/ModalUpdatePasswordDialog.vue";
import AccountInfo from "./views/AccountInfo.vue";
import ConfigEditDialog from "./views/ModalConfigEditDialog.vue";
import MashupDialog from "./views/ModalMashupDialog.vue";
import AddProject from "./views/ModalAddProject.vue";
import EditAddress from "./views/ModalEditAddress.vue";
import imagelogo from "../img/analyze/clientell_logo_light_inv.png"
export default {
  components: {
    Projects, Datasets, Reports, Dashboard, Prospects, ProspectsDashboard, ReportShell, DataShell, RenameDatasetForm, TransferDatasetForm, DatsetUploadDialog, UpdatePasswordDialog, AccountInfo, ConfigEditDialog, MashupDialog, AddProject, RenameProspect, ProspectCriteria, EditAddress
  },
  data() {
    return {
      imagelogo: imagelogo,
      component: Dashboard,
      modalcontent: RenameDatasetForm,
      isAdmin: false,
      passedData: 'Test Default',
      passedModalData: 'Test Default',
      passedMashupSi: '',
      passedMashupDv: '',
      passedMashupBf: '',
      passedMashupRf: '',
      userData: [],
      active: false
    }
  },
  setup() {
      const toast = useToast();
      return { toast }
  },
  methods: {
    projects(){
      this.component = Projects;
    },
    datasets(){
      this.component = Datasets;
    },
    reports(){
      this.component = Reports;
    },
    datashell(){
      this.component = DataShell;
    },
    reportshell(){
      this.component = ReportShell;
    },
    dashboard(){
      this.component = Dashboard;
    },
    prospects(){
      this.component = ProspectsDashboard;
    },
    account() {
      this.component = AccountInfo;
    },
    returnHome() {
      if(this.isAdmin === true) {
        this.component = Projects;
      } else {
        this.component = Dashboard;
      }
    },
    customChange({shell,dataset}) {
      this.component = shell;
      this.passedData = dataset;
    },
    openModal({view,dt}) {
        this.modalcontent = view;
        this.passedModalData = dt;
        const body = document.querySelector("body")
        this.active = !this.active
        this.active ? body.classList.add("modal-open") : body.classList.remove("modal-open")
    },
    closeModal() {
      const body = document.querySelector("body")
      this.active = !this.active
      this.active ? body.classList.add("modal-open") : body.classList.remove("modal-open")
      this.modalcontent = RenameDatasetForm
      this.passedModalData = 'Test Default'
    },
    setMashup({si,dv,bf,rf}) {
        this.modalcontent = MashupDialog;
        this.passedMashupSi = si;
        this.passedMashupDv = dv;
        this.passedMashupBf = bf;
        this.passedMashupRf = rf;
        const body = document.querySelector("body")
        this.active = !this.active
        this.active ? body.classList.add("modal-open") : body.classList.remove("modal-open")
    },
    customLoadReport({shell,dataset}) {
      this.component = shell;
    },
    messageAlert({alert,classel}) {
      if(classel == 'success') {
        this.toast.success(alert, {
        timeout: 4000
      });
      } else if (classel == 'error') {
        this.toast.error(alert, {
        timeout: 4000
      });
      } else if (classel == 'warning') {
        this.toast.warning(alert, {
        timeout: 4000
      });
      } else {
        this.toast.info(alert, {
        timeout: 4000
      });
      }
    },
    onServerSideUpdateTimer() {
      axios.get('/update_needed', {})
      .then(
        (response) => {
          if(response.data == 'TRUE') {
            this.messageAlert({alert: 'Process is complete.', classel: 'success'});
          }
        }
      )
      .catch(
        (error) => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
          this.messageAlert({alert: 'There was an error!', classel: 'error'})
          }
      );
    }
  },
  mounted() {
    axios.get('/user_info', { params: {dataset: 'test'} })
		.then(
			(response) => {
        this.userData = response.data;
        if( this.userData.roles.match(new RegExp("(?:^|,)Administrator(?:,|$)"))) {
          this.isAdmin = true;
          this.component = Projects;
        }
			}
		)
    .catch(
      (error) => {
        this.errorMessage = error.message;
        console.error("There was an error!", error);
        }
    );
    setInterval(this.onServerSideUpdateTimer, 3000);
  }
}
</script>