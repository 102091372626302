<template>
<form id="addressUpdateForm" method="post">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Update Address</h5>

            <button type="button" class="btn-close" @click="$emit('closeModal')"></button>
          </div>
          <div class="modal-body">

            <div class="container-fluid">
                <div class="row">

                    <div class="col-12">
                        <div class="form-group" :class="{ error: v$.form.address.$errors.length }">
                            <label class="form-label">Address</label>
                            <input type="text" class="form-control form-control-lg" name="address" v-model="v$.form.address.$model" />
                            <div class="input-errors" v-for="(error, index) of v$.form.address.$errors" :key="index">
                                <div class="error-msg">{{ error.$message }}</div>
                            </div>
                        </div>
                    </div>
                                                        
                    <div class="col-12">
                        <div class="form-group">
                            <label class="form-label">Address 2</label>
                            <input type="text" class="form-control form-control-lg" name="addresstwo" :value="this.userData.addresstwo" />
                        </div>
                    </div>
                                                    
                    <div class="col-sm-4">
                        <div class="form-group" :class="{ error: v$.form.city.$errors.length }">
                            <label class="form-label">City</label>
                            <input type="text" class="form-control form-control-lg" name="city" v-model="v$.form.city.$model" />
                            <div class="input-errors" v-for="(error, index) of v$.form.city.$errors" :key="index">
                                <div class="error-msg">{{ error.$message }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="form-group" :class="{ error: v$.form.state.$errors.length }">
                            <label class="form-label">State</label>
                            <select id="inputState" class="form-select form-select-lg" name="state" v-model="v$.form.state.$model">
                                <option selected>Choose...</option>
                                <option value="AK">AK</option>
                                <option value="AL">AL</option>
                                <option value="AR">AR</option>
                                <option value="AS">AS</option>
                                <option value="AZ">AZ</option>
                                <option value="CA">CA</option>
                                <option value="CO">CO</option>
                                <option value="CT">CT</option>
                                <option value="DC">DC</option>
                                <option value="DE">DE</option>
                                <option value="FL">FL</option>
                                <option value="GA">GA</option>
                                <option value="GU">GU</option>
                                <option value="HI">HI</option>
                                <option value="IA">IA</option>
                                <option value="ID">ID</option>
                                <option value="IL">IL</option>
                                <option value="IN">IN</option>
                                <option value="KS">KS</option>
                                <option value="KY">KY</option>
                                <option value="LA">LA</option>
                                <option value="MA">MA</option>
                                <option value="MD">MD</option>
                                <option value="ME">ME</option>
                                <option value="MI">MI</option>
                                <option value="MN">MN</option>
                                <option value="MO">MO</option>
                                <option value="MP">MP</option>
                                <option value="MS">MS</option>
                                <option value="MT">MT</option>
                                <option value="NC">NC</option>
                                <option value="ND">ND</option>
                                <option value="NE">NE</option>
                                <option value="NH">NH</option>
                                <option value="NJ">NJ</option>
                                <option value="NM">NM</option>
                                <option value="NV">NV</option>
                                <option value="NY">NY</option>
                                <option value="OH">OH</option>
                                <option value="OK">OK</option>
                                <option value="OR">OR</option>
                                <option value="PA">PA</option>
                                <option value="PR">PR</option>
                                <option value="RI">RI</option>
                                <option value="SC">SC</option>
                                <option value="SD">SD</option>
                                <option value="TN">TN</option>
                                <option value="TX">TX</option>
                                <option value="UM">UM</option>
                                <option value="UT">UT</option>
                                <option value="VA">VA</option>
                                <option value="VI">VI</option>
                                <option value="VT">VT</option>
                                <option value="WA">WA</option>
                                <option value="WI">WI</option>
                                <option value="WV">WV</option>
                                <option value="WY">WY</option>
                            </select>
                            <div class="input-errors" v-for="(error, index) of v$.form.state.$errors" :key="index">
                                <div class="error-msg">{{ error.$message }}</div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-4">
                        <div class="form-group" :class="{ error: v$.form.zipcode.$errors.length }">
                            <label class="form-label">Zip</label>
                            <input type="text" class="form-control form-control-lg" name="zipcode" v-model="v$.form.zipcode.$model" />
                            <div class="input-errors" v-for="(error, index) of v$.form.zipcode.$errors" :key="index">
                                <div class="error-msg">{{ error.$message }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-light" @click="$emit('closeModal')">Cancel</button>
            <button type="button" class="btn btn-primary" @click.prevent="processAddress()" :disabled="v$.form.$invalid">Update</button>
          </div>
        </div>
      </form>
</template>
<script>
import useVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import axios from 'axios';
export default {
  name: 'ModalPasswordChange',
  setup () {
    return {
        v$: useVuelidate()
    }
  },
  data () {
    return {
      form: {
        address: this.userData.address,
        city: this.userData.city,
        state: this.userData.state,
        zipcode: this.userData.zipcode
      }
    }
  },
  validations () {
    return {
        form: {
            address: { required },
            city: { required },
            state: { required },
            zipcode: { required }
        }
    }
  },
  props: {
    userData: []
  },
  methods: {
    processAddress(event) {
      const intialFormData = new FormData(document.getElementById("addressUpdateForm"));
      const serializedData = new URLSearchParams(intialFormData).toString();
      console.log(serializedData);
      axios({
        method: "POST",
        url: "/update_user_record",
        data: serializedData
      })
      .then(
			(response) => {
        console.log(response);
        this.$emit('messageAlert', {alert: 'Your account has been updated.', classel: 'success'});
        this.$emit('closeModal');
      })
      .catch(
			(error) => {
        console.log(error);
        console.log('form submit failed');
      })
    }
    
  }
}
</script>