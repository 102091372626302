<template>
<div id="dataset-elem">
  <div class="content-header text-center">
    <h2>Prospects</h2>
  </div>
  <div class="container-fluid min-content-height">
    <div class="row">
        <div class="col-sm">
            <a class="btn col-md-6 offset-md-3 btn-lg btn-info" @click="$emit('customChange', {shell: 'Prospects', dataset: String(data)})"><span class="sidebar-text">Create new Prospect List</span></a>
            <hr>
        </div>
    </div>

    <div class="row">
      <div class="col-lg-6 offset-lg-3">
        <div class="btn-group-vertical col-12"  v-for='data in datasets' v-bind:key="data">
          <div class="btn-group mb-2" role="group">
            <a class="btn col-8 btn-lg btn-primary" v-bind:href="'/generate_download_prospect?prospect='+data+'.csv'"  v-bind:download="data + '.csv'" target="_blank"><span class="sidebar-text">{{data.replace('.prospect','')}}</span></a>
            <a class="btn col-2 btn-lg btn-danger" @click="customDeleteDataset(String(data))"><i class="fas fa-trash-alt"></i></a>
            <a class="btn col-2 btn-lg btn-info" @click="$emit('openModal', {view: 'RenameProspect', dt: String(data)})"><i class="fas fa-edit"></i></a>
            <a class="btn col-2 btn-lg btn-light" @click="$emit('openModal', {view: 'ProspectCriteria', dt: String(data)})"><i class="fas fa-info"></i></a>
            <!-- a class="btn col-2 btn-lg btn-warning" @click="$emit('openModal', {view: 'TransferDatasetForm', dt: String(data)})"><i class="fas fa-random"></i></a -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


</template>
<script>
import axios from 'axios';
var DomParser = require('dom-parser');
var parser = new DomParser();
var host = window.location.origin;
export default {
  name: 'ProspectDashbord',
  props: {
    userData: [],
  },
  data () {
    return {
      datasets: [],
      userListData: [],
      component: '',
      active: false,
      activeTwo: false,
      selectedValue: ''
    }
  },
  methods: {
    returnUpdatedList() {
      axios({
        method : 'POST',
        url : '/list_datasets',
        data:'',
      }).then(response => this.datasets = processProspects(response.data))
        .catch(error => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
      });
    },
    customDeleteDataset(filename) {
      axios.get('/remove_dataset', { params: {dataset: filename} })
      .then(
			(response) => {
        this.$emit('messageAlert', {alert: 'Deleting selected Prospect List.', classel: 'info'});
        this.returnUpdatedList();
        //this.$emit('customChange', {shell: 'Datasets', dataset: ''});
      }).catch(error => {
        this.errorMessage = error.message;
        this.$emit('messageAlert', {alert: 'There was an error!', classel: 'error'});
        console.error("There was an error!", error);
      });
    }
  },
  mounted () {
    axios({
      method : 'POST',
      url : '/list_datasets',
      data:'',
    }).then(response => this.datasets = processProspects(response.data))
      .catch(error => {
        this.errorMessage = error.message;
        console.error("There was an error!", error);
    });
  }    
}
function processProspects(data) {
      var iH = [];
      var i;
      var b = data;
      for (i = 0; i < b.length; i++) {
        if (b[i].includes('scored-') || !b[i].includes('prospect'))
          continue;
            iH.push(b[i]);
      };
      return iH;
}
</script>