<template>
  <div class="content-header text-center">
    <h2>Reports</h2>
  </div>
  <div class="container-fluid min-content-height">
    <div class="row">
      <div class="col-lg-6 offset-lg-3">
        <div class="btn-group-vertical col-12" v-for='data in reports'>
          <a class="btn btn-lg btn-primary mb-2" @click="$emit('customChange', {shell: 'ReportShell', dataset: String(data)})"><span class="sidebar-text">{{data}}</span></a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';
var DomParser = require('dom-parser');
var parser = new DomParser();
var host = window.location.origin;
export default {
  name: 'app',
  data () {
    return {
      reports: [],
      loading: false,
      component: ''
    }
  }, 
  mounted () {
    axios({
      method : 'POST',
      url : '/list_reports',
      data:'',
    }).then(response => this.reports = processReports(response.data))
      .catch(error => {
        this.errorMessage = error.message;
        console.error("There was an error!", error);
      });
  },
};

function processReports(data) {
  var iH = [];
      var i;
      var b = data;
      for (i = 0; i < b.length; i++) {
        if (b[i].includes('scored-'))
          continue;
            iH.push(b[i]);  
      };
      return iH;
}
</script>