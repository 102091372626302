<template>
    <div>
        <div class="py-4">
            <div class="dropdown">
                <div class="alert alert-secondary" role="alert">
                    <p class="lead">Welcome Back, {{this.userData.user}}</p>
                    <p v-if="this.isAdmin"><em>Administrator</em></p>
                    <p v-else><em>{{this.userData.roles}}</em></p>
                </div>
            </div>
        </div>
        <div class="content-header text-center">
            <h2>Projects</h2>
        </div>
        <div class="container-fluid min-content-height">
            <div id="projectlist" class="row">
            </div>
            <div class="row">
                <div class="col-sm-4 offset-md-2">
                    <div class="card card-body text-center">
                        <h3><i class="fas fa-plus-circle"></i></h3>
                        <div id="create-project" class="btn btn-lg btn-block btn-primary btn-load-project" data-bs-toggle="modal" data-bs-target="#addProject">Select</div>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="card card-body text-center">
                        <h3><i class="fas fa-minus-circle"></i></h3>
                        <div id="delete-project" class="btn btn-lg btn-block btn-primary btn-load-project" onclick="deleteCookie('currentproject')">Reset (Select All)</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Projects',
    props: {
        userData: [],
        isAdmin: {
            type: Boolean,
            default() {
                return false
            }
        }
    },
    data() {
        return {
        }
    },
    mounted () {
        // console.log('ORG TEST : ' + this.userData.organization);
        //console.log('User: ' + this.userData.roles);
        //this.$emit('isAdmin');
    }
}
</script>