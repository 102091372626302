<template>
<form id="mashupForm" method="post">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="mashupModalLabel">Select Matching Criteria</h5>

            <button type="button" class="btn-close" @click="$emit('closeModal')"></button>
          </div>
          <div class="modal-body">
            <input type="hidden" id="mashupRetainFilename" v-model="mashupRetainFilename" name="filename">
            <div class="form-group" id="mashupFormFields">
              <label class="small mb-1" for="mashupFirstName">First Name</label>
              <select name="firstname" id="mashupFirstName" v-model="mashupFirstName" class="form-select">
                <option v-for="option in firstNameOptions" :value="option.id">
                  {{ option.name}}
                </option>
              </select><br>
              <label class="small mb-1" for="mashupLastName">Last Name</label>
              <select name="lastname" id="mashupLastName" v-model="mashupLastName" class="form-select">
                <option v-for="option in lastNameOptions" :value="option.id">
                  {{ option.name}}
                </option>
              </select><br>
              <label class="small mb-1" for="mashupAddress">Address</label>
              <select name="address" id="mashupAddress" v-model="mashupAddress" class="form-select">
                <option v-for="option in addressOptions" :value="option.id">
                  {{ option.name}}
                </option>
              </select><br>
              <label class="small mb-1" for="mashupZipCode">Zip Code</label>
              <select name="zipcode" id="mashupZipCode" v-model="mashupZipCode" class="form-select">
                <option v-for="option in zipCodeOptions" :value="option.id">
                  {{ option.name}}
                </option>
              </select><br>
              <label class="small mb-1" for="mashupBaseline">Baseline for Comparison</label>
              <select name="baseline" id="mashupBaseline" v-model="mashupBaseline" class="form-select">
                <option v-for="option in baselineOptions" :value="option.id">
                  {{ option.name}}
                </option>
              </select>
            </div>

          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-light" @click="$emit('closeModal')">Cancel</button>
            <button type="button" class="btn btn-primary" id="mashupContinueButton" v-on:click="processUpload()">Continue</button>
          </div>
        </div>
      </form>
</template>
<script>
import axios from 'axios';
import { onMounted, ref } from "vue";
export default {
  name: 'ModalMashupDialog',
  props: {
    passedModalData: {
      type: String,
      default() {
        return 'Dataset'
      }
    },
    passedMashupSi: {
      type: String,
      default() {
        return 'Dataset'
      }
    },
    passedMashupDv: {
      type: String,
      default() {
        return 'Dataset'
      }
    },
    passedMashupBf: {
      type: String,
      default() {
        return 'Dataset'
      }
    },
    passedMashupRf: {
      type: String,
      default() {
        return 'Dataset'
      }
    }
  },
  data(){
    return{
      mashupRetainFilename: '',
      mashupFirstName: '',
      mashupLastName: '',
      mashupAddress: '',
      mashupZipCode: '',
      mashupBaseline: '',
      firstNameOptions: [],
      lastNameOptions: [],
      addressOptions: [],
      zipCodeOptions: [],
      baselineOptions: []
    }
  },
  methods: {
    processUpload(event) {
      const intialFormData = new FormData(document.getElementById("mashupForm"));
      const serializedData = new URLSearchParams(intialFormData).toString();
      axios({
        method: "POST",
        url: "/confirm_mashup",
        data: serializedData
      })
      .then(
			(response) => {
        // console.log(response);
        this.$emit('messageAlert', {alert: 'Your dataset is being processed and will be available in a few minutes.', classel: 'info'});
        this.$emit('closeModal');
      })
      .catch(
			(error) => {
        console.log(error);
        console.log('form submit failed');
      })
    }
  },
  mounted() {
    this.mashupRetainFilename = this.passedMashupRf;
    for (var j = 0; j < this.passedMashupDv.length; j++) {
      this.firstNameOptions.push({ id: j.toString(), name: this.passedMashupDv[j] });
      this.lastNameOptions.push({ id: j.toString(), name: this.passedMashupDv[j] });
      this.addressOptions.push({ id: j.toString(), name: this.passedMashupDv[j] });
      this.zipCodeOptions.push({ id: j.toString(), name: this.passedMashupDv[j] });
    }
    this.mashupFirstName = this.passedMashupSi[0];
    this.mashupLastName = this.passedMashupSi[1];
    this.mashupAddress = this.passedMashupSi[2];
    this.mashupZipCode = this.passedMashupSi[3];

    for (var i = 0; i < this.passedMashupBf.length; i++) {
      this.baselineOptions.push({ id: this.passedMashupBf[i], name: this.passedMashupBf[i] });
    }
    this.mashupBaseline = this.passedMashupBf[0];
  }
}

</script>