<template>
<div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="configEditLabel">Edit Configuration File</h5>

          <button type="button" class="btn-close" @click="$emit('closeModal')"></button>
        </div>
        <div class="modal-body">
          <form id="configEditForm" method="post">
            <div class="form-group">
              <select name="cfgTarget" id="cfgTarget" class="form-select"
                onchange="if (this.selectedIndex) getConfigFileContents($('#cfgTarget').val(););">
                <option value="model.cfg">Predictive Model</option>
              </select>
            </div>
            <div class="form-group">
              <textarea id="cfgContents" name="cfgContents" class="form-control" rows="15" cols="45"></textarea>
            </div>
            <div class="form-group">
              <button type="button" class="btn btn-light" @click="$emit('closeModal')">Cancel</button>
              <button type="button" class="btn btn-primary" data-bs-dismiss="modal"
                id="configUpdateButton">Update</button>
            </div>
          </form>
        </div>
      </div>
</template>
<script>
export default {
}
</script>