<template>
<div class="mb-3 prospect-logic">
      <div class="container-fluid">
        <div class="row h-100">
          <div class="col-sm-5 my-auto">
            <label class="form-label">Category</label>
            <select class="form-select" v-model="selectedProspect" @change="selectDrink">
              <option v-for="(prospect,index) in prospectData" :value="index" :key="prospect.label">{{ prospect.label }}</option>
            </select>
            </div>
            <div class="col-sm-5 my-auto">
              <label class="form-label">Sub Category</label>
              <select class="form-select" v-model="selectedOption" v-if="selectedProspect != -1" @change="submitSelectedValue($event.target.value)">
                <option v-for="option in prospectData[selectedProspect].options" :value="option.value" :key="option.value">{{ option.name }}</option>
              </select>
            </div>
          </div>
        </div>
    </div>
</template>
<script>
export default {
  name: 'prospectList',
  props: {
    prospectData: {
      type: Array,
      default:()=>[]
    }
  },
  data() {
    return {
      prospectData:[
      {
        label:"Purchases",
        options:[
        {"value": "BUY_MO_BUYER", "name": "Buy Items Via Mail"},
        {"value": "BUY_MO_RESPDR", "name": "Respond to Offers Via Mail"},
        {"value": "BUY_OL_PURCH_IND", "name": "Buy Items Online"},
        {"value": "BUY_MEM_CLUBS", "name": "Buy From Membership Clubs"},
        {"value": "BUY_VALUE_PRICED", "name": "Buy Value-Priced Items"},
        {"value": "BUY_WMNS_APPAREL", "name": "Buy Women's Apparel"},
        {"value": "BUY_WMNS_PETITE_APPAREL", "name": "Buy Petite-Sized Women's Apparel"},
        {"value": "BUY_WMNS_PLUS_APPAREL", "name": "Buy Plus-Sized Women's Apparel"},
        {"value": "BUY_YOUNG_WMNS_APPAREL", "name": "Buy Young Women's Apparel"},
        {"value": "BUY_MNS_APPAREL", "name": "Buy Men's Apparel"},
        {"value": "BUY_MNS_BIG_APPAREL", "name": "Buy Men's Big and Tall Apparel"},
        {"value": "BUY_YOUNG_MNS_APPAREL", "name": "Buy Young Men's Apparel"},
        {"value": "BUY_KIDS_APPAREL", "name": "Buy Children's Apparel"},
        {"value": "BUY_HEALTH_BEAUTY", "name": "Buy Heath and Beauty Items"},
        {"value": "BUY_COSMETICS", "name": "Buy Cosmetics"},
        {"value": "BUY_JEWELRY", "name": "Buy Jewelry"},
        {"value": "BUY_LUGGAGE", "name": "Buy Luggage"}
        ]
      },
      {
        label:"Credit Card",
        options:[
        {"value": "CC_AMEX_PREM", "name": "Have an American Express Premium Card"},
        {"value": "CC_AMEX_REG", "name": "Have an American express Card"},
        {"value": "CC_DISC_PREM", "name": "Have a Discover Premium Card"},
        {"value": "CC_DISC_REG", "name": "Have a Discover Card"},
        {"value": "CC_GAS_PREM", "name": "Have a Premium Gas Card"},
        {"value": "CC_GAS_REG", "name": "Have a Gas Card"},
        {"value": "CC_MC_PREM", "name": "Have a Premium Mastercard"},
        {"value": "CC_MC_REG", "name": "Have a Mastercard"},
        {"value": "CC_VISA_PREM", "name": "Have a Premium Visa Card"},
        {"value": "CC_VISA_REG", "name": "Have a Visa Card"},
        {"value": "CC_HLDR_BANK", "name": "Have a Bank Credit Card"},
        {"value": "CC_HLDR_GAS", "name": "Have a Gas Credit Card"},
        {"value": "CC_HLDR_TE", "name": "Have a Travel or Entertainment Rewards Credit Card"},
        {"value": "CC_HLDR_UNK", "name": "Have a Credit Card (Other or Unknown)"},
        {"value": "CC_HLDR_PREM", "name": "Have a Premium Credit Card"},
        {"value": "CC_HLDR_UPS_DEPT", "name": "Have an Upscale Department Store Card"},
        {"value": "CC_USER", "name": "Use a Credit Card for Purchases"},
        {"value": "CC_NEW_ISSUE", "name": "Have a Recent Credit Card"},
        {"value": "CC_BANK_CD_IN_HH", "name": "Have a Credit Card in the Household"}
        ]
      },
      {
        label:"Investing",
        options:[
          {"value": "INVEST_ACT", "name": "Be an Active Investor"},
          {"value": "INVEST_PERS", "name": "Manage Personal Investments"},
          {"value": "INVEST_RL_EST", "name": "Invest In Real Estate"},
          {"value": "INVEST_STOCKS", "name": "Invest in the Stock Market"},
          {"value": "INVEST_READ_FIN_NEWS", "name": "Read Financial News"},
          {"value": "INVEST_MONEY_SEEKR", "name": "Seek Money for Investing"},
          {"value": "INT_GRP_INVEST", "name": "Be Interested in Investing"},
          {"value": "INVEST_FOREIGN", "name": "Have Foreign Investments"},
          {"value": "INVEST_EST_PROP_OWN", "name": "Own Investment Properties"}
        ]
      },
      {
        label:"Donations",
        options:[
          {"value": "INT_GRP_DONOR", "name": "Support Charitable Causes"},
          {"value": "DONR_MAIL_ORD", "name": "Donate to a Charity by Mail"},
          {"value": "DONR_CHARITABLE", "name": "Make a Charitable Contribution"},
          {"value": "DONR_ANIMAL", "name": "Donate to Animal Welfare Causes"},
          {"value": "DONR_ARTS", "name": "Donate to the Arts"},
          {"value": "DONR_KIDS", "name": "Donate to Children's Causes"},
          {"value": "DONR_WILDLIFE", "name": "Donate to Wildelife Causes"},
          {"value": "DONR_ENVIRON", "name": "Donate to Environmental Causes"},
          {"value": "DONR_HEALTH", "name": "Donate to Health and Wellness Causes"},
          {"value": "DONR_INTL_AID", "name": "Donate to International Aid Organizations"},
          {"value": "DONR_POL", "name": "Make a Political Contribution"},
          {"value": "DONR_POL_CONS", "name": "Support Conservative Political Causes"},
          {"value": "DONR_POL_LIB", "name": "Support Liberal Political Causes"},
          {"value": "DONR_RELIG", "name": "Donate to Religious Causes"},
          {"value": "DONR_VETS", "name": "Donate to Veteran's Causes"},
          {"value": "DONR_OTH", "name": "Donate to Other Charities"},
          {"value": "DONR_COMM_CHAR", "name": "Donate to Community Causes"}
        ]
      },
      {
        label:"Household",
        options:[
          {"value": "VET_IN_HH", "name": "Have a Veteran in the Household"},
          {"value": "INT_OTH_PARENTING", "name": "Have an Interest in Parenting"},
          {"value": "SINGLE_PARENT", "name": "Be a Single Parent"},
          {"value": "BUY_INFANT_APPAREL", "name": "Buy Infant's Apparel"},
          {"value": "BUY_KIDS_LEARN_TOYS", "name": "Buy Educational Toys"},
          {"value": "BUY_KIDS_BABY_CARE", "name": "Buy Baby Care Items"},
          {"value": "BUY_KIDS_SCHOOL", "name": "Buy School Supplies"},
          {"value": "BUY_KIDS_GENL", "name": "Buy Children's General Merchandise"},
          {"value": "YOUNG_ADULT_IN_HH", "name": "Have a Young Adult in the Household"},
          {"value": "SR_ADULT_IN_HH", "name": "Have an Older Adult in the Household"},
          {"value": "INT_OTH_KIDS_INTS", "name": "Have Children's Interests"},
          {"value": "INT_OTH_GRANDKIDS", "name": "Have Grandchildren"},
          {"value": "INT_OTH_CHRISTIAN_FAM", "name": "Be a Christian Family"}
        ]
      },
      {
        label:"Animals",
        options:[
          {"value": "BUY_PETS", "name": "Buy Pet Supplies"},
          {"value": "INT_OTH_PETS_HORSE", "name": "Have a Horse"},
          {"value": "INT_OTH_PETS_CAT", "name": "Have a Cat"},
          {"value": "INT_OTH_PETS_DOG", "name": "Have a Dog"},
          {"value": "INT_OTH_PETS_OTH", "name": "Have a Pet (Other)"}
        ]
      },
      {
        label:"Career",
        options:[
          {"value": "INT_OTH_CAREER_IMP", "name": "View their Career as Important"},
          {"value": "OCC_WORKING_WMN", "name": "Have a Professional Woman in the Household"},
          {"value": "OCC_AFRIC_AMER_PROF", "name": "Have an African-American Professional in the Household"},
          {"value": "OCC_SOHO_IND", "name": "Use a Home Office for Work"},
          {"value": "INT_OTH_CAREER", "name": "Be Career-Focused"}
        ]
      },
      {
        label:"Reading",
        options:[
          {"value": "BUY_MAGAZINES", "name": "Buy Magazines"},
          {"value": "BUY_BOOKS", "name": "Buy Books"},
          {"value": "BUY_AUDIO_BOOKS", "name": "Buy Audio Books"},
          {"value": "READ_AVID", "name": "Be an Avid Reader"},
          {"value": "READ_RELIG", "name": "Read Religious and Inspirational Books"},
          {"value": "READ_SCIFI", "name": "Read Science Fiction Books"},
          {"value": "READ_MAGAZINES", "name": "Read Magazines"},
          {"value": "READ_AUDIO_BOOKS", "name": "Listen to Audio Books"},
          {"value": "INT_GRP_READ", "name": "Be Interested in Reading"},
          {"value": "READ_HIST_MLTRY", "name": "Read History and Military Books"},
          {"value": "READ_CURR_AFFAIRS", "name": "Read Books about Current Affairs"},
          {"value": "BUY_RELIGIOUS", "name": "Buy Religious Books and Items"},
          {"value": "READ_SCIENCE_SPACE", "name": "Read about Science and Space"},
          {"value": "MAGS", "name": "Buy Magazines"}
        ]
      },
      {
        label:"Interests",
        options:[
          {"value": "ENT_EDUC_OL", "name": "Be Interested in Online Education"},
          {"value": "ENT_GAMING", "name": "Be Interested in Video Games"},
          {"value": "OCC_COMP_GENL", "name": "Be Interested in Computers"},
          {"value": "ENT_DVD_VIDEOS", "name": "Buy DVD Videos"},
          {"value": "ENT_TV_VIDEO_MOVIE", "name": "Watch TV and Movies"},
          {"value": "OCC_HOME_OFF", "name": "Have an Office in the Home"},
          {"value": "ENT_HI_END_APPL", "name": "Have High End Appliances"},
          {"value": "ENT_STEREO", "name": "Have a Stereo"},
          {"value": "ENT_MUSIC_PLAYR", "name": "Have a Music Player"},
          {"value": "ENT_MUSIC_COLL", "name": "Collect Music"},
          {"value": "ENT_MUSIC_AVID", "name": "Be an Avid Music Fan"},
          {"value": "ENT_MOVIE_COLL", "name": "Collect Movies"},
          {"value": "ENT_CABLE_TV", "name": "Have Cable Television"},
          {"value": "ENT_VIDEO_GAMING", "name": "Play Video Games"},
          {"value": "ENT_SAT_DISH", "name": "Have a Satellite Dish"},
          {"value": "ENT_COMPUTERS", "name": "Use a Computer for Entertainment"},
          {"value": "ENT_PC_GAMING", "name": "Use a Comptuer for Gaming"},
          {"value": "ENT_CONS_ELEC", "name": "Be Interested in Consumer Electronics"},
          {"value": "INT_GRP_MOVIE_MUSIC", "name": "Be interested in Movies and Music"},
          {"value": "INT_GRP_ELEC", "name": "Be Interested in Technology"},
          {"value": "INT_OTH_TELCOMM", "name": "Be Interested in Cell Phones and Telecommunication"},
          {"value": "BUY_ANTIQUES", "name": "Buy Antiques"},
          {"value": "BUY_ART", "name": "Collect Art"},
          {"value": "ENT_THEATER", "name": "Attend the Theater"},
          {"value": "INT_OTH_THE_ARTS", "name": "Have an Interest in the Arts"},
          {"value": "ENT_MUS_INSTR", "name": "Play a Musical Instrument"},
          {"value": "INT_COLL_GENL", "name": "Be Interested in Collectables"},
          {"value": "INT_COLL_STAMPS", "name": "Collect Stamps"},
          {"value": "INT_COLL_COINS", "name": "Collect Coins"},
          {"value": "INT_COLL_ARTS", "name": "Collect Arts and Crafts"},
          {"value": "INT_COLL_ANTIQUES", "name": "Collect Antiques"},
          {"value": "INT_COLL_AVID", "name": "be an Avid Collector"},
          {"value": "INT_GRP_COLL", "name": "Have a Hobby Collection"},
          {"value": "INT_COLL_SPORTS", "name": "Collect Sports Items and Memorabilia"},
          {"value": "INT_COLL_MIL", "name": "Collect Military Items and Memorabilia"},
          {"value": "BUY_COLLECTIBLES", "name": "Buy Collectibles"},
          {"value": "INT_HOB_DIY", "name": "Have a DIY Hobby"},
          {"value": "INT_AUTO_WK", "name": "Be Interested in Auto Repair"},
          {"value": "INT_HOB_SEW", "name": "Sew Knit or Crochet"},
          {"value": "INT_HOB_WOODWK", "name": "Enjoy Woodworking"},
          {"value": "INT_HOB_PHOTO", "name": "Enjoy Photography"},
          {"value": "INT_AVIATION", "name": "Enjoy Aviation"},
          {"value": "INT_HOB_CRAFTS", "name": "Enjoy Crafts and Handiwork"},
          {"value": "INT_HOB_GARDENING", "name": "Enjoy Gardening"},
          {"value": "BUY_GARDENING", "name": "Buy Gardening Supplies"},
          {"value": "BUY_HOME_GARD", "name": "Shop for Home and Garden Items"},
          {"value": "INT_GRP_HOME_IMP", "name": "Be Interested in Gardening and Home Improvement"},
          {"value": "BUY_CRAFTS", "name": "Buy Craft Items"},
          {"value": "BUY_PHOTO_VIDEO", "name": "Buy Photo and Video Items"},
          {"value": "INT_OTH_SMOKING", "name": "Smoke Cigars Pipes or Other Tobacco"},
          {"value": "INT_OTH_HOME_DEC", "name": "Be Interested in Home Decorating"},
          {"value": "INT_HOB_HOME_IMP", "name": "Have a Home Improvement Hobby"},
          {"value": "INT_OTH_COOK_WINE", "name": "Collect Wine"},
          {"value": "INT_OTH_COOK_GNRL", "name": "Be Interested in Cooking"},
          {"value": "INT_OTH_COOK_GOURMET", "name": "Cook Gourmet Foods"},
          {"value": "INT_OTH_COOK_NAT_FOOD", "name": "Cook Organic and Natural Foods"},
          {"value": "INT_GRP_COOK", "name": "Cook at Home"},
          {"value": "INT_HOB_GAMES", "name": "Be Interested in Games"},
          {"value": "INT_TRAV_CASINO", "name": "Gamble at a Casino"},
          {"value": "INT_HOB_SWEEPS", "name": "Participate in Sweepstakes"}
        ]
      },
      {
        label:"Travel",
        options:[
          {"value": "INT_GRP_TRAVEL", "name": "Travel"},
          {"value": "INT_TRAV_GENL", "name": "Travel for Fun"},
          {"value": "INT_TRAV_US", "name": "Travel in the United States"},
          {"value": "INT_TRAV_INTL", "name": "Travel Internationally"},
          {"value": "INT_TRAV_CRUISE", "name": "Take a Pleasure Cruise"}
        ]
      },
      {
        label:"Lifestyle",
        options:[
          {"value": "LIFE_HOME", "name": "Have a Home/Garde Lifestyle"},
          {"value": "LIFE_DIY", "name": "Have a DIY Lifestyle"},
          {"value": "LIFE_SPORTY", "name": "Have a Sports Lifestyle"},
          {"value": "LIFE_UPSCALE", "name": "Have an Upscale Lifestyle"},
          {"value": "LIFE_CULTURE", "name": "Have a Culture/Arts Lifestyle"},
          {"value": "LIFE_HIGHBROW", "name": "Have a Highbrow Lifestyle"},
          {"value": "LIFE_HT", "name": "Have a High Tech Lifestyle"},
          {"value": "LIFE_PROF", "name": "Have a Professional Lifestyle"}
        ]
      },
      {
        label:"Active",
        options:[
          {"value": "INT_GRP_EXER", "name": "Be Interested In Exercise"},
          {"value": "INT_FIT_JOG", "name": "Jog for Exercise"},
          {"value": "INT_FIT_WALK", "name": "Walk For Exercise"},
          {"value": "INT_FIT_AEROB", "name": "Do Aerobics"}
        ]
      },
      {
        label:"Sports",
        options:[
          {"value": "INT_SPORT_SPECT_AUTO", "name": "Watch Auto Racing"},
          {"value": "INT_SPORT_SPECT_TV_SPORTS", "name": "Watch Television Sports"},
          {"value": "INT_SPORT_SPECT_FOOT", "name": "Watch Football"},
          {"value": "INT_SPORT_SPECT_BASE", "name": "Watch Baseball"},
          {"value": "INT_SPORT_SPECT_BSKT", "name": "Watch Basketball"},
          {"value": "INT_SPORT_SPECT_HOCKEY", "name": "Watch Hockey"},
          {"value": "INT_SPORT_SPECT_SOCCER", "name": "Watch Soccer"},
          {"value": "INT_SPORT_TENNIS", "name": "Play Tennis"},
          {"value": "INT_SPORT_GOLF", "name": "Play Golf"},
          {"value": "INT_SPORT_SNOW_SKI", "name": "Ski"},
          {"value": "INT_SPORT_MTRCYCL", "name": "Be Interested in Motorcycles"},
          {"value": "INT_SPORT_NASCAR", "name": "Watch Nascar"},
          {"value": "INT_SPORT_BOATING", "name": "Be Interested in Boating"},
          {"value": "INT_SPORT_SCUBA", "name": "Be Interested in SCUBA Diving"},
          {"value": "BUY_SPORT_LEIS", "name": "Have a Sports or Leisure Interest"}
        ]
      },
      {
        label:"Outdoors",
        options:[
          {"value": "BUY_HUNTING", "name": "Hunt for Sport"},
          {"value": "INT_SPORT_FISHING", "name": "Fish for Sport"},
          {"value": "INT_SPORT_CAMP", "name": "Enjoy Camping and Outdoors"},
          {"value": "INT_SPORT_SHOOT", "name": "Be Interested in Shooting and Firearms"},
          {"value": "INT_GRP_SPORTS", "name": "Have a Sports Hobby"},
          {"value": "INT_GRP_OUTDOOR", "name": "Have an Outdoor Interest"}
        ]
      }
    ],
    selectedProspect:-1,
    selectedOption:''
    }
  },
  methods: {
    submitSelectedValue(value) {
      //console.log('selected value is ' + value);
      this.$emit('customChangeProsp', value);
    }
  }
}
</script>