<template>
    <div id="heatmap">
        <div id="mapcanvas"></div>
    </div>
</template>
<script>
import axios from "axios";
import { onMounted, ref, defineAsyncComponent } from "vue";
export default {
  name: "app",
  props: {
    passedData: {
      type: String,
      default() {
        return "Dataset";
      },
    },
  },
  data() {
    return {
    };
  },
  mounted() {
    const reportName = this.passedData;
    axios
      .get("/user_report", {
        params: { report: reportName + ".csv.mapPoints.json", format: "html" },
      })
      .then((response) => {
        let mainData = response.data;
        let map;
        function initMap() {
          let d = mainData;
          let centroid = d[0];
          let pointPairs = d[1];
          let centlock = centroid.toString().split(",");
          map = new google.maps.Map(document.getElementById("mapcanvas"), {
            center: { lat: parseFloat(centlock[0]), lng: parseFloat(centlock[1]) },
            zoom: 8,
          });
          var bounds = new google.maps.LatLngBounds();
          var infowindow = new google.maps.InfoWindow();
          var markers = [];
          for (let i = 0; i < pointPairs.length; i++) {
            var curloc = pointPairs[i].toString().split(",");
            var marker = new google.maps.Marker({
              position: new google.maps.LatLng(
                parseFloat(curloc[0]),
                parseFloat(curloc[1])
              ),
              map: map,
            });
            markers.push(marker);
            bounds.extend(marker.position);
          }
          var markerCluster = new MarkerClusterer(map, markers);
          map.fitBounds(bounds);
          var listener = google.maps.event.addListener(map, "idle", function () {
            map.setZoom(5);
            google.maps.event.removeListener(listener);
          });
        }
        initMap();
      });
  }
};
</script>
