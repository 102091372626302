document.addEventListener('DOMContentLoaded', function () {
    // start

    // Toggle the side navigation
   // var sideToggle = document.getElementById('sidebarToggle');
    //sideToggle.addEventListener('click', function(e){
        //e.preventDefault();
        //document.body.classList.toggle('sb-sidenav-toggled');
    //});

    var sidebar = document.getElementById('sidebarMenu')
        // console.log('printed value: ' + document.body.clientWidth);
    if(sidebar && document.body.clientWidth < 991) {
        sidebar.addEventListener('shown.bs.collapse', function () {
            document.querySelector('body').style.position = 'fixed';
        });
        sidebar.addEventListener('hidden.bs.collapse', function () {
            document.querySelector('body').style.position = 'relative';
        });
    }

    // click outside sidenav (mobile)

    //var myCollapse = document.getElementById('sidebarMenu')
    //$(document).on('click', function (e){
        //var menu_opened = $('#sidebarMenu').hasClass('show');
        //if(!$(e.target).closest('#sidebarMenu').length &&
            //!$(e.target).is('#sidebarMenu') &&
            //menu_opened === true){
                //new bootstrap.Collapse(myCollapse, {
                    //toggle: true
                //})
        //}
    //});

    // end click outside sidenav
    document.addEventListener("DOMContentLoaded", function(event) {
        var iconNotifications = document.querySelector('.notification-bell');
        if (iconNotifications) {
            iconNotifications.addEventListener('shown.bs.dropdown', function () {
                iconNotifications.classList.remove('unread');
            });
        }
    });
    var elements = document.getElementsByClassName("delete-self");
    var deleteSelfAlert = function(e) {
        const target = e.target;
        target.remove();
    };
    for (var i = 0; i < elements.length; i++) {
        elements[i].addEventListener('click', deleteSelfAlert, false);
    }
    // end
}, false);