<template>
<div class="content-header text-center">
	<h2>{{passedData}}</h2>
</div>
<div class="tabbed-padding">
	<ul class="nav nav nav-tabs" id="myTab" role="tablist">
		<li class="nav-item">
			<a class="nav-link active" id="original-tab" ref="original-tab" data-bs-toggle="tab" href="#original" role="tab" aria-controls="original" aria-selected="true">Original Upload</a>
		</li>
		<li class="nav-item">
			<a class="nav-link" id="scored-tab" ref="scored-tab" data-bs-toggle="tab" href="#scored" role="tab" aria-controls="scored" aria-selected="false">Propensity Model</a>
		</li>
		<li class="nav-item">
			<a class="nav-link" id="appended-tab" ref="appended-tab" data-bs-toggle="tab" href="#appended" role="tab" aria-controls="appended" aria-selected="false">Appended Consumer Data</a>
		</li>
	</ul>
	<div class="tab-content" id="myTabContent" ref="myTabContent">
		<div class="tab-pane fade show active" id="original" ref="original" role="tabpanel" aria-labelledby="original-tab">
			<div class="card mt-4">
				<div class="card-header" id="original_card_title" ref="original_card_title"></div>
				<div class="card-body" id="original_card_body" ref="original_card_body"></div>
			</div>
		</div>
		<div class="tab-pane fade" id="scored" ref="scored" role="tabpanel" aria-labelledby="scored-tab">
			<div class="card mt-4">
				<div class="card-header" id="scored_card_title" ref="scored_card_title"></div>
				<div class="card-body" id="scored_card_body" ref="scored_card_body"></div>
			</div>
		</div>
		<div class="tab-pane fade" id="appended" ref="appended" role="tabpanel" aria-labelledby="appended-tab">
			<div class="card mt-4">
				<div class="card-header" id="appended_card_title" ref="appended_card_title"></div>
				<div class="card-body" id="appended_card_body" ref="appended_card_body"></div>
			</div>
		</div>
	</div>
</div>
</template>
<script>
import axios from 'axios';
import { onMounted, ref } from 'vue'; 
import {DataTable} from 'simple-datatables';
export default {
  name: 'app',
  props: {
    passedData: {
      type: String,
      default() {
        return 'Dataset'
      }
    }
  },
  data: function() {
	},
	mounted() {
		const datasetName = this.passedData;
		const appendNodeTarget = this.$refs['appended_card_body'];
		const placedDatatable = this.$refs['original_card_body'];
		const scoredDatatable = this.$refs['scored_card_body'];
		axios.get('/user_dataset', { params: {dataset: datasetName + '.csv', format: 'html', objid: 'tblOriginal' } })
		.then(
			(response) => {
			const placedtable = response.data;
			placedDatatable.innerHTML = placedtable;
			const dataTablePlaced = new DataTable("#tblOriginal");
			let elementorig = document.getElementById('tblOriginal');
			elementorig.classList.add('table');
			}
		)
		axios.get('/user_dataset', { params: {dataset: 'scored-' + datasetName + '.csv', format: 'html', objid: 'tblScored' } })
		.then(
			(response) => {
			const scoredtable = response.data;
			scoredDatatable.innerHTML = scoredtable;
			const scoredDatatablePlaced = new DataTable("#tblScored");
			let elementscore = document.getElementById('tblScored');
			elementscore.classList.add('table');
			}
		)
  		const s = '<a target="_blank" href="/user_dataset?dataset=appended-' + datasetName +
            '.csv&format=raw&objid=null" download="appended-' + datasetName +
            '.csv"><img src="/static/csv.png" width="100"><br>Download...</a>';
		appendNodeTarget.innerHTML = s;
  }
}
</script>