<template>
<div id="dataset-elem">
  <div class="content-header text-center">
    <h2>Datasets</h2>
  </div>
  <div class="container-fluid min-content-height">
    <div class="row">
      <div class="col-lg-6 offset-lg-3">
        <div class="btn-group-vertical col-12" v-for='data in datasets'>
          <div class="btn-group mb-2" role="group" aria-label="Basic example">
            <a class="btn col-6 btn-lg btn-primary" @click="$emit('customChange', {shell: 'DataShell', dataset: String(data)})"><span class="sidebar-text">{{data}}</span></a>
            <a class="btn col-2 btn-lg btn-danger" @click="customDeleteDataset(String(data))"><i class="fas fa-trash-alt"></i></a>
            <a class="btn col-2 btn-lg btn-info" @click="$emit('openModal', {view: 'RenameDatasetForm', dt: String(data)})"><i class="fas fa-edit"></i></a>
            <a v-if="this.userData.organization == 'my_organization'" class="btn col-2 btn-lg btn-warning" @click="$emit('openModal', {view: 'TransferDatasetForm', dt: String(data)})"><i class="fas fa-random"></i></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>
<script>
import axios from 'axios';
var DomParser = require('dom-parser');
var parser = new DomParser();
var host = window.location.origin;
export default {
  name: 'app',
  data () {
    return {
      userData: [],
      datasets: [],
      component: '',
      selectedValue: ''
    }
  },
  mounted () {
    axios({
      method : 'POST',
      url : '/list_datasets',
      data:'',
    }).then(response => this.datasets = processDatasets(response.data))
      .catch(error => {
        this.errorMessage = error.message;
        console.error("There was an error!", error);
    });
  },
  methods : {
    returnUpdatedList() {
      axios({
        method : 'POST',
        url : '/list_datasets',
        data:'',
      }).then(response => this.datasets = processDatasets(response.data))
        .catch(error => {
          this.errorMessage = error.message;
          console.error("There was an error!", error);
      });
    },
    customDeleteDataset(filename) {
      axios.get('/remove_dataset', { params: {dataset: filename} })
      .then(
			(response) => {
        this.$emit('messageAlert', {alert: 'Deleting selected Dataset.', classel: 'info'});
        this.returnUpdatedList();
        //this.$emit('customChange', {shell: 'Datasets', dataset: ''});
      }).catch(error => {
        this.errorMessage = error.message;
        this.$emit('messageAlert', {alert: 'There was an error!', classel: 'error'});
        console.error("There was an error!", error);
    });
    }
  }
}
function processDatasets(data) {
      var iH = [];
      var i;
      var b = data;
      for (i = 0; i < b.length; i++) {
        if (b[i].includes('scored-') || b[i].includes('prospect'))
          continue;
            iH.push(b[i]); 
      };
      return iH;
}
</script>