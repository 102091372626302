<template>
<form id="passwordUpdateForm" method="post">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="passwordModalLabel">Update Password</h5>

            <button type="button" class="btn-close" @click="$emit('closeModal')"></button>
          </div>
          <div class="modal-body">

            <div class="form-group" :class="{ error: v$.form.password.$errors.length }">
              <label class="small mb-1">New Password</label>
              <input class="form-control py-4" name="password" type="password" id="password"
                placeholder="Enter new password" v-model="v$.form.password.$model" />
              <div class="input-errors" v-for="(error, index) of v$.form.password.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>

            <div class="form-group" :class="{ error: v$.form.passwordconfirm.$errors.length }">
              <label class="small mb-1">Confirm New Password</label>
              <input class="form-control py-4" name="passwordconfirm" id="passwordconfirm" type="password"
                placeholder="Confirm New Password" v-model="v$.form.passwordconfirm.$model" />
              <div class="input-errors" v-for="(error, index) of v$.form.passwordconfirm.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>

            <hr>

             <div class="form-group" :class="{ error: v$.form.oldpassword.$errors.length }">
              <label class="small mb-1">Old Password</label>
              <input class="form-control py-4" name="oldpassword" type="password"
                placeholder="Enter existing password"  v-model="v$.form.oldpassword.$model" />
                <div class="input-errors" v-for="(error, index) of v$.form.oldpassword.$errors" :key="index">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-light" @click="$emit('closeModal')">Cancel</button>
            <button type="button" class="btn btn-primary" @click.prevent="processPassword()" :disabled="v$.form.$invalid">Update</button>
          </div>
        </div>
      </form>
</template>
<script>
import useVuelidate from '@vuelidate/core'
import { helpers, required, minLength, sameAs} from '@vuelidate/validators'
import axios from 'axios';
export default {
  name: 'ModalPasswordChange',
  setup () {
    return {
      v$: useVuelidate()
    }
  },
  data () {
    return {
      form: {
        password: '',
        passwordconfirm: '',
        oldpassword: ''
      }
    }
  },
  validations () {
    return {
      form: {
        password: {
          required,
          minLength: minLength(6),
          containsPasswordRequirement: helpers.withMessage(
            () => `The password requires an uppercase, lowercase, number and special character`, 
            (value) => /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])/.test(value)
          ),
        },
        passwordconfirm: { required, sameAsPassword: sameAs(this.form.password) },
        oldpassword: { required }
      }
    }
  },
  props: {},
  methods: {
    processPassword(event) {
      const intialFormData = new FormData(document.getElementById("passwordUpdateForm"));
      const serializedData = new URLSearchParams(intialFormData).toString();
      axios({
        method: "POST",
        url: "/update_password",
        data: serializedData
      })
      .then(
			(response) => {
        // console.log(response);
        this.$emit('messageAlert', {alert: 'Your password has been changed.', classel: 'success'});
        this.$emit('closeModal');
      })
      .catch(
			(error) => {
        console.log(error);
        console.log('form submit failed');
      })
    }
    
  }
}
</script>