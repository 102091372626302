<template>
<form id="transferDatasetForm" method="post" @submit.prevent="customTransferDataset">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="transferDatasetLabel">Share Dataset: {{passedModalData}}</h5>
            <button type="button" class="btn-close" @click="$emit('closeModal')" aria-label="Close"></button>
          </div>
          <div class="modal-body">

            <div class="form-group">
  	          <input type="hidden" name="old_dataset" id="transferDatasetOriginalName" :value="passedModalData">
                <label class="small mb-1" for="inputDataset">User to Share With</label>
                <select name="transfer_username" id="transferUsersSelect" class="form-select">
                  <option v-for="option in userListData" :value="option.uid">{{option.user}}</option>
                </select>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="$emit('closeModal')">Cancel</button>
            <button type="submit" class="btn btn-primary">Share</button>
          </div>
        </div>
      </form>
</template>
<script>
import axios from 'axios';
export default {
  name: 'ModalTransferDatasetForm',
  data () {
    return {
      userListData: []
    }
  },
  props: {
    passedModalData: {
      type: String,
      default() {
        return 'Dataset'
      }
    }
  },
  mounted() {
    axios({
      method : 'POST',
      url : '/list_transfer_users',
      data:'',
    }).then(
    (response) => {
      this.userListData = response.data;
      // console.log(JSON.stringify(this.userListData));
    })
      .catch(error => {
        this.errorMessage = error.message;
        console.error("There was an error!", error);
    });

  },
  methods: {
    customTransferDataset() {
      const olddat = document.getElementById("transferDatasetOriginalName").value;
      const newuser = document.getElementById("transferUsersSelect").value;
      const intialFormData = new FormData(document.getElementById("transferDatasetForm"));
      const serializedData = new URLSearchParams(intialFormData).toString()
      // console.log(serializedData);
      axios.post('/transfer_dataset', {headers: {'Content-type': 'application/x-www-form-urlencoded'}, body: serializedData}, { params: {old_dataset: olddat , transfer_username: newuser}})
      .then(
			(response) => {
        // console.log(response);
        this.$emit('messageAlert', {alert: 'Transferring Dataset', classel: 'info'});
        this.$emit('closeModal');
      })
      .catch(
			(error) => {
        console.log(error);
        console.log('form submit failed');
      });
    }
  }
}
function processUsers(data) {
  console.log(JSON.stringify(data));
  var iH = [];
  var i;
  var b = data;
  for (i = 0; i < b.length; i++) {
    iH.push(b[i]);
  };
  console.log(JSON.stringify(iH));
  return iH;
}
</script>